import JoditEditor from 'jodit-react';
import { FC } from 'react';
import { CONSTANTS } from '../../constants';

const joditConfig = {
  readonly: false, // all options from https://xdsoft.net/jodit/docs/,
  placeholder: 'Your content here',
  cleanHTML: {
    allowTags: CONSTANTS.JODIT.ALLOWED_TAGS
  },
  style: {
    textAlign: 'left',
    height: '300px'
  }
};

interface PostContentTextAreaProps {
  onChange: (content: string) => void
  value: string
}

const PostContentTextArea: FC<PostContentTextAreaProps> = (
  props: PostContentTextAreaProps,
) => {
  return (
      <JoditEditor
        onBlur={props.onChange}
        onChange={newContent => {}}
        value={props.value}
        config={joditConfig}
      />
  )
}

export default PostContentTextArea
