import { Pane, Strong, majorScale, toaster } from 'evergreen-ui'
import React, { FC, useEffect, useState } from 'react'
import { CONSTANTS } from '../../constants'
import { PostDetailRateOptions } from '../../routes/CommunityPage/PostDetailPage/PostDetailPageConstants'
import {
  CreateRatingSurveyRequestPayload,
  Post,
  RateTabGroupOption,
  Rating,
  SaveRatingRequestPayload,
  SurveyStage,
} from '../../types'
import RateTabGroup from '../RateTabGroup/RateTabGroup'
import { cloneDeep } from 'lodash'
import styles from './RatingWithSurvey.module.scss'
import QuickSurvey from '../QuickSurvey/QuickSurvey'
import {
  useSaveRatingMutation,
  useSaveRatingSurveyMutation,
} from '../../services/ratings/ratings.service'
import { PatientVideosDetailRateOptions } from '../../routes/PatientVideosDetailPage/PatientVideosDetailPageConstants'

interface RatingWithSurveyProps {
  route: string
  userId: string
  videoId?: string
  ratings?: Rating[]
  displayText?: string
  upvotes?: number
  postId?: string
}

const RatingWithSurvey: FC<RatingWithSurveyProps> = (
  props: RatingWithSurveyProps,
) => {
  const [ratingOptions, setRatingOptions] = useState<RateTabGroupOption[]>([])
  const [activeRating, setActiveRating] = useState<Rating | undefined>(
    undefined,
  )
  const [activeRatingValue, setActiveRatingValue] = useState<any>(undefined)
  const [localUpvotes, setLocalUpvotes] = useState<number | undefined>(
    undefined,
  )
  const [surveyStage, setSurveyStage] = useState<SurveyStage>('HIDDEN')

  // set the rating options on mount
  useEffect(() => {
    let opts: RateTabGroupOption[] = []
    switch (props.route) {
      case CONSTANTS.ROUTES.COMMUNITY:
        opts = cloneDeep(PostDetailRateOptions)
        break
      case CONSTANTS.ROUTES.PATIENT_VIDEOS:
        opts = cloneDeep(PatientVideosDetailRateOptions)
        break
    }
    setRatingOptions(opts)
  }, [])

  // set the basic parameters once the post is loaded
  useEffect(() => {
    setActiveRating(props.ratings?.find((r) => r.user_id === props.userId))
    setLocalUpvotes(props.upvotes ?? 0)
  }, [props.ratings, props.upvotes])

  // show the count on the page
  useEffect(() => {
    updateRatingCount(localUpvotes ?? 0)
  }, [localUpvotes])

  // define the save rating API
  const [
    callCreateRatingApi,
    { isLoading: isSavingRating },
  ] = useSaveRatingMutation()

  // define the save survey API
  const [
    callSaveRatingSurveyApi,
    { isLoading: isSavingSurvey, isSuccess: isSurveySaved },
  ] = useSaveRatingSurveyMutation()

  const saveSurvey = async (
    surveyPayload: CreateRatingSurveyRequestPayload,
  ) => {
    // send the request to server
    const surveyResponse: Rating = await callSaveRatingSurveyApi(
      surveyPayload,
    ).unwrap()
    setActiveRating(surveyResponse)
    setSurveyStage('SUCCESS')
  }

  // Create Rating for post
  const rate = async (rating: number) => {
    const ratingPayload: SaveRatingRequestPayload = {
      id: activeRating?.id,
      user_id: props.userId,
      rating: rating,
      post_id: props.postId,
      video_id: props.videoId,
    }

    try {
      // then update the visible count locally
      const upvoteCount = localUpvotes ?? 0
      const upvotes = upvoteCount + rating >= 0 ? upvoteCount + rating : 0

      // first update the active counts locally
      setActiveRatingValue(rating)
      setLocalUpvotes(upvotes)
      updateRatingCount(upvotes)
      setSurveyStage('VISIBLE')

      // then send the request to server
      const ratingResponse: Rating = await callCreateRatingApi(
        ratingPayload,
      ).unwrap()

      // then update the local rating
      setActiveRating(ratingResponse)
    } catch (exception) {
      toaster.danger('Error saving your rating.')
    }
  }

  // show the latest count on the page
  const updateRatingCount = (count: number) => {
    const ratings = [...ratingOptions]
    const upvoteOptionIndex = ratingOptions.findIndex(
      (r) => r.value === CONSTANTS.RATINGS.VALUES.UPVOTE,
    )

    if (upvoteOptionIndex > -1) {
      ratings[upvoteOptionIndex].badgeText = `${count}`
      setRatingOptions(ratings)
    }
  }

  return (
    <Pane>
      <Strong marginRight={majorScale(1)}>{props.displayText}</Strong>
      <RateTabGroup
        options={ratingOptions}
        onRate={(rating: number) => {
          rate(rating)
        }}
        activeRating={activeRating}
      ></RateTabGroup>
      <Pane marginTop={majorScale(2)}>
        <QuickSurvey
          activeRating={activeRating}
          userId={props.userId}
          type={
            activeRatingValue === CONSTANTS.RATINGS.VALUES.UPVOTE
              ? CONSTANTS.RATINGS.TYPES.HELPFUL
              : CONSTANTS.RATINGS.TYPES.NOT_HELPFUL
          }
          onSubmit={(payload) => saveSurvey(payload)}
          surveyStage={surveyStage}
          isSaving={isSavingSurvey}
          isSurveySaved={isSurveySaved}
        ></QuickSurvey>
      </Pane>
    </Pane>
  )
}

export default RatingWithSurvey
