import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import styles from './DashboardPage.module.scss'

interface DashboardPageProps {}

const DashboardPage: FC<DashboardPageProps> = () => {
  return (
    <Outlet></Outlet>
  )
}

export default DashboardPage
