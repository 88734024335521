import { User } from '../types'

const deletedId = 'DELETED'

export const generateUsername = (user?: User) => {
    let name = ''
    if (user) {
        if (user.id === deletedId) {
            return 'Deleted User'
        }
        const returnString = `${user.prefix ? user.prefix + ' ' : ''}${user.first_name ? user.first_name + ' ' : ''
            }${user.last_name ? user.last_name : ''}${user.suffix ? ', ' + user.suffix + ' ' : ''
            }`
        name = returnString !== '' ? returnString : user.email
    }
    return name
}

export const generateFullName = (user?: User) => {
    let name = ''
    if (user) {
        if (user.id === deletedId) {
            return 'Deleted User'
        }
        name =
            user.first_name !== ''
                ? `${user.first_name} ${user.last_name}`
                : user.email
    }
    return name
}
