import {
  Button,
  Heading,
  majorScale,
  Option,
  Pane,
  Select,
  SelectMenu,
} from 'evergreen-ui'
import React, { FC, useState } from 'react'
import styles from './Sort.module.scss'

interface SortProps {
  options: string[]
  selected: string
  sort: Function
  appearance?: 'minimal' | 'default'
  title?: string
}

const Sort: FC<SortProps> = (props: SortProps) => {
  return (
    <Pane
      className={
        props.appearance === 'default'
          ? styles.SortContainer
          : styles.SortContainerMinimal
      }
    >
      <Heading
        display="inline-block"
        size={400}
        marginRight={majorScale(1)}
        className={
          props.appearance === 'default'
            ? styles.SortHeading
            : styles.SortHeadingMinimal
        }
      >
        {props.title ?? 'Sort:'}
      </Heading>
      <Select
        title="Sort"
        value={props.selected}
        onChange={(event) => {
          props.sort(event.target.value)
        }}
        className={
          props.appearance === 'default' ? styles.Select : styles.SelectMinimal
        }
        width="100%"
      >
        {props.options.map((opt) => (
          <option
            value={opt}
            key={opt}
            className={
              props.appearance === 'default'
                ? styles.SelectOption
                : styles.SelectOptionMinimal
            }
          >
            {opt}
          </option>
        ))}
      </Select>
    </Pane>
  )
}

export default Sort
