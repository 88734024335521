import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CONSTANTS } from '../../constants'
import { Filter, FilterOption, Taxonomy, Video } from '../../types'
import { sortHelper } from '../../utils/sort'
import { findLastIndex, remove, uniq, xorBy } from 'lodash'

// Define a type for the slice state
interface PatientVideosState {
  activeFilters: Filter[]
  allFilters: FilterOption[]
  originalFilters: Taxonomy[]
  allVideos: Video[]
  filteredVideos: Video[]
  activeSort: string
}

// Define the initial state using that type
const initialState: PatientVideosState = {
  allVideos: [],
  filteredVideos: [],
  allFilters: [],
  activeFilters: [],
  originalFilters: [],
  activeSort: CONSTANTS.SORT.PATIENT_VIDEOS.DEFAULT,
}

export const patientVideosSlice = createSlice({
  name: 'patientVideosSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    toggleFilter: (state, action: PayloadAction<Filter>) => {
      // create a copy of the filters array
      let newActiveFilters = [...state.activeFilters]
      let allFilterOptions = [...state.allFilters]
      const filterToToggle = action.payload

      // remove any active filters at this supercategory and level+
      remove(
        newActiveFilters,
        (f) =>
          f.level >= filterToToggle.level &&
          f.super_category &&
          f.super_category === filterToToggle.super_category,
      )
      // remove any filter options filters at this supercategory and level+
      remove(
        allFilterOptions,
        (f) =>
          f.level > filterToToggle.level &&
          f.super_category === filterToToggle.super_category,
      )

      // add the new filter
      newActiveFilters = xorBy(newActiveFilters, [filterToToggle], (f) => f.id)

      const isTurningOn =
        newActiveFilters.find((f) => f.id === filterToToggle.id) !== undefined

      // if i toggle on a filter
      // generate the children
      if (
        isTurningOn &&
        filterToToggle.children &&
        filterToToggle.children.length > 0
      ) {
        const newFilterOption = generateFilters(filterToToggle.children)[0]
        const latestCategoryFilterIndex = findLastIndex(allFilterOptions, {
          super_category: filterToToggle.super_category,
        })
        allFilterOptions = [
          // part of the array before the specified index
          ...allFilterOptions.slice(0, latestCategoryFilterIndex + 1),
          // inserted item
          newFilterOption,
          // part of the array after the specified index
          ...allFilterOptions.slice(latestCategoryFilterIndex + 1),
        ]
      }

      // set the new state
      state.activeFilters = newActiveFilters
      state.allFilters = allFilterOptions
    },
    setAllVideos: (state, action: PayloadAction<Video[]>) => {
      state.allVideos = action.payload
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setFilters: (state, action: PayloadAction<Taxonomy[] | undefined>) => {
      let filters: FilterOption[] = []

      if (action.payload) {
        state.originalFilters = action.payload
        filters = generateFilters(action.payload)
      }
      // set the filters based on the videos available (all or filtered)
      state.allFilters = filters
    },
    clearFilters: (state, action: PayloadAction<undefined>) => {
      state.activeFilters = []
      state.allFilters = generateFilters(state.originalFilters)
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setFilteredVideos: (state, action: PayloadAction<Filter[] | undefined>) => {
      if (!state.activeFilters.length) {
        // if no active filter, show all videos
        state.filteredVideos = state.allVideos
      } else {
        // create a dictionary of active tags to check against
        const consolidatedActiveTag = state.activeFilters.flatMap(
          (af) => `${af.category}--${af.value}--${af.level}`,
        )

        // check if the video tags are part of the active filter set
        let videos: Video[] = []
        state.allVideos.filter((vid) => {
          const vidTags = vid.taxonomy?.flatMap(
            (af) => `${af.category}--${af.value}--${af.level}`,
          )

          // if every category exists on a viddo, add it to the new filter list
          if (consolidatedActiveTag.every((cat) => vidTags.includes(cat))) {
            videos = [...videos, vid]
          }
        })
        state.filteredVideos = videos
      }
    },
    sortVideos: (state, action: PayloadAction<string>) => {
      let videos = [...state.filteredVideos]
      state.activeSort = action.payload

      videos = sortHelper(videos, action.payload)

      state.filteredVideos = videos
    },
  },
})

const generateFilters = (taxonomies: Taxonomy[]) => {
  let filters: FilterOption[] = []

  let categories = uniq(taxonomies.flatMap((t) => t.category))
  // iterate over the keys
  categories.map((cat) => {
    // get the options for each key
    let options = taxonomies.filter((t) => t.category === cat)

    // build the filter option
    const option: FilterOption = {
      category: cat,
      super_category: options[0].super_category,
      level: options[0].level,
      filters: options,
    }

    // set the filters
    filters = [...filters, option]
  })

  return filters
}

export const {
  toggleFilter,
  setFilters,
  setFilteredVideos,
  setAllVideos,
  sortVideos,
  clearFilters,
} = patientVideosSlice.actions

export default patientVideosSlice.reducer
