import {
  Alert,
  FileCard,
  FileRejection,
  FileRejectionReason,
  FileUploader,
  majorScale,
  MimeType,
  rebaseFiles,
} from 'evergreen-ui'
import { uniq, uniqBy } from 'lodash'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './MultiFileUploader.module.scss'

interface MultiFileUploaderProps {
  onChange: (files: File[]) => void
}

const MultiFileUploader: FC<MultiFileUploaderProps> = (
  props: MultiFileUploaderProps,
) => {
  const acceptedMimeTypes = [MimeType.jpeg, MimeType.gif, MimeType.png]
  const maxFiles = 5
  const maxSizeInBytes = 10 * 1024 ** 2 // 10 MB
  const [files, setFiles] = useState<File[]>([])
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const values = useMemo(
    () => [
      ...files,
      ...fileRejections.map((fileRejection) => fileRejection.file),
    ],
    [files, fileRejections],
  )

  // propogate changes once files are added
  useEffect(() => {
    props.onChange(files)
  }, [files])

  const handleRemove = useCallback(
    (file: File) => {
      const updatedFiles = files.filter((existingFile) => existingFile !== file)
      const updatedFileRejections = fileRejections.filter(
        (fileRejection) => fileRejection.file !== file,
      )

      // Call rebaseFiles to ensure accepted + rejected files are in sync (some might have previously been
      // rejected for being over the file count limit, but might be under the limit now!)
      const { accepted, rejected } = rebaseFiles(
        [
          ...updatedFiles,
          ...updatedFileRejections.map((fileRejection) => fileRejection.file),
        ],
        { acceptedMimeTypes, maxFiles, maxSizeInBytes },
      )

      setFiles(accepted)
      setFileRejections(rejected)
    },
    [acceptedMimeTypes, files, fileRejections, maxFiles, maxSizeInBytes],
  )

  const fileCountOverLimit = files.length + fileRejections.length - maxFiles
  const fileCountError = `You can upload up to 5 files. Please remove ${fileCountOverLimit} ${
    fileCountOverLimit === 1 ? 'file' : 'files'
  }.`

  return (
    <FileUploader
      acceptedMimeTypes={acceptedMimeTypes}
      label=""
      description="You can upload up to 5 files. Files can be up to 10MB. You can upload JPG, PNG, and GIF file formats."
      disabled={files.length + fileRejections.length >= maxFiles}
      maxSizeInBytes={maxSizeInBytes}
      maxFiles={maxFiles}
      onAccepted={(newFiles) => setFiles(uniqBy([...files, ...newFiles], x => x.name))}
      onRejected={setFileRejections}
      renderFile={(file, index) => {
        const { name, size, type } = file
        const renderFileCountError = index === 0 && fileCountOverLimit > 0

        // We're displaying an <Alert /> component to aggregate files rejected for being over the maxFiles limit,
        // so don't show those errors individually on each <FileCard />
        const fileRejection = fileRejections.find(
          (fileRejection) =>
            fileRejection.file === file &&
            fileRejection.reason !== FileRejectionReason.OverFileLimit,
        )
        const { message } = fileRejection || {}

        return (
          <React.Fragment key={`${file.name}-${index}`}>
            {renderFileCountError && (
              <Alert
                intent="danger"
                marginBottom={majorScale(2)}
                title={fileCountError}
              />
            )}
            <FileCard
              isInvalid={fileRejection != null}
              name={name}
              onRemove={() => handleRemove(file)}
              sizeInBytes={size}
              type={type}
              src={URL.createObjectURL(file)}
              validationMessage={message}
            />
          </React.Fragment>
        )
      }}
      values={values}
    />
  )
}
export default MultiFileUploader
