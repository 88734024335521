import { Badge, Button, Group, majorScale } from 'evergreen-ui'
import React, { FC, MouseEvent, useEffect, useState } from 'react'
import { RateTabGroupOption, Rating } from '../../types'
import styles from './RateTabGroup.module.scss'

interface RateTabGroupProps {
  options: RateTabGroupOption[]
  onRate: (value: any) => void
  activeRating?: Rating
}

const RateTabGroup: FC<RateTabGroupProps> = (props: RateTabGroupProps) => {
  const [selected, setSelected] = useState<any>(undefined)

  // update the value once any updates come from parent
  useEffect(() => {
    setSelected(props.activeRating?.rating)
  }, [props.activeRating])

  // update the state locally then call teh parent function
  const rate = (value: any) => {
    setSelected(value)
    props.onRate(value)
  }

  return (
    <Group>
      {props.options.map((option) => (
        <Button
          key={option.id}
          value={option.value}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            // Disable the button if it is already selected
            if (selected === option.value) {
              e.preventDefault()
            } else {
              rate(option.value)
            }
          }}
          isActive={selected === option.value}
        >
          {option.displayText}
          {option.badgeText ? (
            <Badge marginLeft={majorScale(1)}>{option.badgeText}</Badge>
          ) : null}
        </Button>
      ))}
    </Group>
  )
}

export default RateTabGroup
