import {
  Button,
  Icon,
  Image,
  LockIcon,
  Pane,
  Tab,
  Tablist,
  majorScale,
} from 'evergreen-ui'
import { NavLink } from 'react-router-dom'

import React, { FC, useEffect, useState } from 'react'
import { CONSTANTS } from '../../constants'
import { useAuth } from '../../hooks/auth.hook'
import { GetGroupsForUserResponse } from '../../types'
import { sendFeedbackEmail } from '../../utils/send-feedback-email'
import { AppNavLinks } from './AppTabs'
import styles from './SideNav.module.scss'


interface SideNavProps {
  children?: React.ReactNode
}

const SideNav: FC<SideNavProps> = (props: SideNavProps) => {

  const { getGroupsForUser } = useAuth()
  const [authGroups, setAuthGroups] = useState<string[] | undefined>([])

  // when the user logs in, check their auth groups
  useEffect(() => {
    if (getGroupsForUser) {
      getGroupsForUser()
        .then((res: GetGroupsForUserResponse[]) => {
          if (res) {
            setAuthGroups(
              res.map((group) => group.GroupName)
            )
          }
        })
    }
  }, [getGroupsForUser])

  return (
    <Pane flex={1} flexDirection="column" className={styles.SideNavContainer}>
      <Pane
        borderRight="default"
        borderRadius="10px"
        background="white"
        elevation={2}
        minHeight={`calc(100vh - 68px)`}
        position="sticky"
        top={majorScale(2)}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        padding={majorScale(2)}
      >
        <Pane className={styles.SideNav}>
          <Tablist>
          {AppNavLinks.map((tab, index) => {
              let isExternal = tab.externalUrl !== undefined;
              let disabled = !isExternal && tab.group !== undefined && !authGroups?.includes(tab.group);
              let lockedIcon = disabled && <Icon icon={LockIcon} style={{ position: 'absolute', right: majorScale(6) }}></Icon>;
              ;
              return isExternal ? ( //this is only needed for external routes in the side nav
                <a
                  key={index}
                  id={tab.name}
                  href={tab.externalUrl}
                  target="_blank" 
                  rel="noopener noreferrer"
                  className={styles.SideNavTab}
                  color={disabled ? '#999999' : 'black'}
                  style={{
                    padding: majorScale(3),
                    fontSize: "1rem",
                    textDecoration: "none",  
                    borderRadius: "10px",
                    marginTop: "8px"  ,  
                    color: "black"    
                  }}
                >
                  <Icon icon={tab.icon} marginRight={majorScale(2)}></Icon>
                  {tab.name}
                  </a>
              ) : (
                <Tab
                  key={index}
                  id={tab.name}
                  is={NavLink}
                  to={disabled ? (tab.unauthorizedUrl ?? '#') : tab.route}
                  target={disabled ? "_blank" : ""}
                  color={disabled ? '#999999' : 'black'}
                  direction="vertical"
                  padding={ majorScale(3) }
                  borderRadius="10px"
                  className={styles.SideNavTab}
                  fontSize="1rem"
                >
                  <Icon icon={tab.icon} marginRight={majorScale(2)}></Icon>
                  {tab.name}
                  {lockedIcon}
                </Tab>
              );
              })}
          </Tablist>
        </Pane>
        <Pane
          textAlign="center"
          onClick={() => {
            sendFeedbackEmail()
          }}
          cursor="pointer"
        >
          <Image src="/feedback.svg" maxWidth="100%" width="80%"></Image>
          <Button
            type="button"
            width="100%"
            appearance="primary"
            fontWeight="bold"
            fontSize="0.9rem"
            marginTop={majorScale(1)}
          >
            Share feedback
          </Button>
        </Pane>
      </Pane>
    </Pane>
  )
}

export default SideNav
