import {
  Badge,
  Card,
  Heading,
  Image,
  InboxIcon,
  Pane,
  Pill,
  Strong,
  Text,
  Tooltip,
  TrendingUpIcon,
  majorScale,
  minorScale
} from 'evergreen-ui'
import { FC, useEffect, useState } from 'react'
import { Taxonomy, Video } from '../../types'
import styles from './VideoCard.module.scss'

interface VideoCardProps {
  data?: Video
  navigate: Function
}

const VideoCard: FC<VideoCardProps> = (props: VideoCardProps) => {
  const [cardFilters, setCardFilters] = useState<Taxonomy[]>([])

  useEffect(() => {
    if (props.data) {
      generateCardFilters(props.data.taxonomy)
    }
  }, [props.data])

  const generateCardFilters = (taxonomy: Taxonomy[]) => {
    const filters: Taxonomy[] = []

    taxonomy.map((t) => {
      // if no hierarchy, add it
      if (!t.hierarchy) {
        filters.push(t)
      } else if (t.hierarchy !== undefined) {
        const index = filters.findIndex(
          (f) => (f.hierarchy && (t.hierarchy?.indexOf(f.hierarchy)) || -1) > -1,
        )
        if (index === -1) {
          filters.push(t)
        } else {
          filters[index] = t
        }
      }
    })

    setCardFilters(filters)
  }



  let commentsPastWeekPill = (props: VideoCardProps) => (props.data?.meta?.comments_past_week ?? 0) > 0 && (
    <>
      <Pill color="green" display="flex" alignItems="center">
          <Tooltip content="Trending: comments this week" position="bottom">
            <Text>
                <TrendingUpIcon marginRight={minorScale(1)} size={9} />
                {props.data?.meta?.comments_past_week ?? 0}
            </Text>
          </Tooltip>
      </Pill>
      <Pane marginLeft={minorScale(1)}></Pane>
    </>
  );

  let commentsPill = (props: VideoCardProps) => (props.data?.meta?.comments_count ?? 0) > 0 && (
    <>
      <Pill color="neutral" display="flex" alignItems="center">
          <Tooltip content="Total comments" position="bottom">
            <Text>
                <InboxIcon marginRight={minorScale(1)} size={9} />
                {props.data?.meta?.comments_count}
            </Text>
          </Tooltip>
      </Pill>
      <Pane marginLeft={minorScale(1)}></Pane>
    </>
  );

  return (
    <Card
      className={styles.VideoCard}
      data-testid="VideoCard"
      border={'default'}
      borderRadius={10}
      elevation={1}
      onClick={() => props.navigate()}
      cursor="pointer"
    >
      <Image
        className={styles.VideoCardImage}
        src={props.data?.thumbnail_url}
      ></Image>
      <Pane className={styles.VideoCardContent}>
        <Heading size={500} marginBottom={majorScale(2)}>
          {props.data?.name}
        </Heading>
        <Text>{props.data?.description}</Text>
        {props.data?.cluster ? (
          <Pane marginTop={majorScale(1)}>
            <Strong>
              Video {props.data.sort} of {props.data.meta?.cluster_count ?? 0}
            </Strong>
          </Pane>
        ) : null}
        <Pane></Pane>
        <Pane
          marginTop={majorScale(2)}
          display="flex"
          flexWrap="wrap"
          gap={majorScale(1)}
        >
          {cardFilters.map((tax, index) => (
            <Tooltip content={tax.category} key={tax.id}>
              <Badge
                color="orange"
                isInteractive={true}
                display="inline-block"
                height="auto"
              >
                {tax.hierarchy ?? tax.value}
              </Badge>
            </Tooltip>
          ))}
        </Pane>
        <Pane marginBottom={majorScale(2)}></Pane>
        <Pane display="flex" justifyContent="flex-end">
          {commentsPastWeekPill(props)}
          {commentsPill(props)}
        </Pane>
      </Pane>
    </Card>
  )
}

export default VideoCard
