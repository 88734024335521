import { Badge, Button, majorScale, Pane, Text } from 'evergreen-ui'
import React, { FC, ReactNode } from 'react'
import styles from './RateButton.module.scss'

interface RateButtonProps {
  value: number
  icon: ReactNode
  count?: number
  onClick: (value: number) => void
  isActiveVote?: boolean
  activeColor:
    | 'automatic'
    | 'neutral'
    | 'blue'
    | 'red'
    | 'orange'
    | 'yellow'
    | 'green'
    | 'teal'
    | 'purple'
    | undefined
}

const RateButton: FC<RateButtonProps> = (props: RateButtonProps) => {
  return (
    <Pane display="inline-flex">
      {props.isActiveVote ? (
        <Badge
          color={props.activeColor}
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={majorScale(2)}
        >
          {props.icon}
          <Text fontWeight="bold" marginLeft={majorScale(1)} userSelect="none">
            {props.count ?? 0}
          </Text>
        </Badge>
      ) : (
        <Button
          is="button"
          appearance="minimal"
          onClick={() => props.onClick(props.value)}
          disabled={props.isActiveVote}
        >
          {props.icon}
          <Text fontWeight="bold" marginLeft={majorScale(1)}>
            {props.count ?? 0}
          </Text>
        </Button>
      )}
    </Pane>
  )
}

export default RateButton
