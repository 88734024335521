import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { communitySlice } from './routes/CommunityPage/Community.slice'
import { postDetailPageSlice } from './routes/CommunityPage/PostDetailPage/PostDetailPage.slice'
import { createPostPageSlice } from './routes/CreatePostPage/CreatePostPage.slice'
import { patientVideosDetailSlice } from './routes/PatientVideosDetailPage/PatientVideosDetail.slice'
import { patientVideosSlice } from './routes/PatientVideosPage/PatientVideos.slice'
import { authApi } from './services/auth/auth.service'
import { authSlice } from './services/auth/auth.slice'
import { commentsApi } from './services/comments/comments.service'
import { initializeApi } from './services/initialize/initialize.service'
import { notesApi } from './services/notes/notes.service'
import { postsApi } from './services/posts/posts.service'
import { ratingsApi } from './services/ratings/ratings.service'
import { subscribersApi } from './services/subscribers/subscribers.service'
import { tagsApi } from './services/tags/tags.service'
import { taxonomyApi } from './services/taxonomy/taxonomy.service'
import { uploadsApi } from './services/uploads/uploads.service'
import { usersApi } from './services/users/users.service'
import { videosApi } from './services/videos/videos.service'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    videosApi.reducerPath,
    commentsApi.reducerPath,
    authApi.reducerPath,
    postsApi.reducerPath,
    tagsApi.reducerPath,
    ratingsApi.reducerPath,
    initializeApi.reducerPath,
    taxonomyApi.reducerPath,
    notesApi.reducerPath,
    uploadsApi.reducerPath,
    usersApi.reducerPath,
    subscribersApi.reducerPath,
  ],
}

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  patientVideos: patientVideosSlice.reducer,
  patientVideosDetail: patientVideosDetailSlice.reducer,
  community: communitySlice.reducer,
  postDetailPage: postDetailPageSlice.reducer,
  createPostPage: createPostPageSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [videosApi.reducerPath]: videosApi.reducer,
  [commentsApi.reducerPath]: commentsApi.reducer,
  [postsApi.reducerPath]: postsApi.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  [ratingsApi.reducerPath]: ratingsApi.reducer,
  [initializeApi.reducerPath]: initializeApi.reducer,
  [taxonomyApi.reducerPath]: taxonomyApi.reducer,
  [notesApi.reducerPath]: notesApi.reducer,
  [uploadsApi.reducerPath]: uploadsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [subscribersApi.reducerPath]: subscribersApi.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authApi.middleware,
      videosApi.middleware,
      commentsApi.middleware,
      postsApi.middleware,
      tagsApi.middleware,
      ratingsApi.middleware,
      initializeApi.middleware,
      taxonomyApi.middleware,
      notesApi.middleware,
      uploadsApi.middleware,
      usersApi.middleware,
      subscribersApi.middleware,
    ),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
