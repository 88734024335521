import {
  Button,
  Heading,
  Link,
  majorScale,
  Pane,
  Spinner,
  Text,
  TextInputField,
} from 'evergreen-ui'
import { ChangeEvent, FC, FormEvent, useState } from 'react'
import { CONSTANTS } from '../../constants'
import { useAuth } from '../../hooks/auth.hook'
import { LoginCredentialsPayload } from '../../types'

interface MainLoginProps { }

const MainLogin: FC<MainLoginProps> = () => {
  const [formValue, setFormValue] = useState<LoginCredentialsPayload>({
    username: '',
    password: '',
  })
  const [validationErrors, setValidationErrors] = useState({
    username: false,
    password: false,
  })

  const { login, isLoggingIn } = useAuth()

  const doLogin = (e?: FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault()
    }
    const isValid = validate()

    if (isValid) {
      login(formValue)
    }
  }

  const validate = () => {
    const errors = {
      username: false,
      password: false,
    }

    if (!formValue.username) {
      errors.username = true
    }
    if (!formValue.password) {
      errors.password = true
    }

    setValidationErrors(errors)

    return Object.values(errors).every((value) => value === false)
  }

  return (
    <Pane width="70%">
      <Heading size={800} marginBottom={majorScale(4)}>
        Login
      </Heading>
      <Heading size={900}>MSKexperts.com</Heading>
      <Heading size={800} color="#696F8C" marginBottom={majorScale(4)}>
        Subscriber Portal
      </Heading>
      <form onSubmit={(e) => doLogin(e)}>
        <TextInputField
          id="username"
          label="Username"
          required
          placeholder="Username"
          type={'email'}
          value={formValue.username}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValue({ ...formValue, username: e.target.value })
          }
          name="username"
          isInvalid={validationErrors.password}
        ></TextInputField>

        <TextInputField
          id="password"
          label="Password"
          required
          placeholder="Password"
          type={'password'}
          isInvalid={validationErrors.password}
          name="password"
          value={formValue.password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValue({ ...formValue, password: e.target.value })
          }
        ></TextInputField>

        <Link display="block" marginBottom={majorScale(4)} type="a" href={`${CONSTANTS.ROUTES.LOGIN}/${CONSTANTS.ROUTES.FORGOT_PASSWORD}`} >
          Forgot password?
        </Link>

        <Button
          marginBottom={majorScale(4)}
          fontSize="14px"
          type="submit"
          disabled={isLoggingIn}
        >
          {isLoggingIn ? (
            <Spinner size={majorScale(2)}></Spinner>
          ) : (
            <Text>Login</Text>
          )}
        </Button>
      </form>
      <Pane>
        <Text>Don't have an account?</Text>{' '}
        <Link type="a" href="https://mskexperts.com">
          Get access!
        </Link>
      </Pane>
    </Pane>
  )
}

export default MainLogin
