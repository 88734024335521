import {
  Button,
  EditIcon,
  majorScale,
  Menu,
  MoreIcon,
  Pane,
  Popover,
  Position,
} from 'evergreen-ui'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

interface EditableContextMenuProps {
  // delete: () => void
  edit: () => void
}

const EditableContextMenu: FC<EditableContextMenuProps> = (
  props: EditableContextMenuProps,
) => {
  return (
    <Pane display="flex" alignItems="center">
      <Popover
        position={Position.BOTTOM_RIGHT}
        content={
          <Menu>
            <Menu.Item
              icon={<EditIcon />}
              onClick={() => props.edit()}
              padding={majorScale(1)}
            >
              Edit
            </Menu.Item>
            <Menu.Divider />
          </Menu>
        }
      >
        <Button
          padding={majorScale(2)}
          display="flex"
          justifyContent="center"
          alignItems="center"
          appearance="minimal"
        >
          <MoreIcon color='#7A8A99' />
        </Button>
      </Popover>
    </Pane>
  )
}

export default EditableContextMenu
