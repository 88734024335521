import {
  Pane,
  majorScale,
  Card,
  Heading,
  toaster,
  Alert,
  TextInputField,
  Button,
} from 'evergreen-ui'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { CONSTANTS } from '../../constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setUser } from '../../services/auth/auth.slice'
import { useEditUserMutation } from '../../services/users/users.service'
import { User } from '../../types'
import { generatePageTitle } from '../../utils/generate-page-title'
import styles from './AccountPage.module.scss'

interface AccountPageProps {
  flex?: number
}

const AccountPage: FC<AccountPageProps> = (props: AccountPageProps) => {
  const title = generatePageTitle(CONSTANTS.TITLES.ACCOUNT)

  let [errors, setErrors] = useState<string | undefined>(undefined)
  let [userForm, setUserForm] = useState<User>({
    id: '',
    email: '',
    first_name: '',
    last_name: '',
    prefix: '',
    suffix: '',
    specialities: [],
    is_onboarded: false,
  })

  const dispatch = useAppDispatch()

  const user: User | undefined = useAppSelector((state) => state.auth.user)

  useEffect(() => {
    if (user) {
      setUserForm(user)
    }
  }, [user])

  // edit a user
  const [
    callEditUserApi,
    { isLoading: isUpdatingUser, isSuccess: isEditSaveSuccess },
  ] = useEditUserMutation()

  const validate = () => {
    if (userForm.first_name.length === 0 || userForm.last_name.length === 0) {
      setErrors('Please complete the required fields.')
    } else {
      setErrors(undefined)
      submitForm()
    }
  }

  const submitForm = async () => {
    const payload: User = {
      ...userForm,
    }
    try {
      const savedUser: User = await callEditUserApi(payload).unwrap()
      dispatch(setUser(savedUser))

      toaster.success('Account updated!')
    } catch (exception) {
      toaster.danger('Error updating account.')
    }
  }

  return (
    <Pane
      flex={props.flex ?? 1}
      className={styles.AccountPage}
      data-testid="AccountPage"
      padding={majorScale(4)}
      background="tint2"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Heading size={900} marginBottom={majorScale(2)}>
        Account
      </Heading>
      <Card
        border="default"
        padding={majorScale(2)}
        display="flex"
        flexDirection="column"
        background="white"
        marginBottom={majorScale(2)}
      >
        {errors ? (
          <Alert intent="danger" marginBottom={majorScale(2)}>
            {errors}
          </Alert>
        ) : null}

        <TextInputField
          label="Prefix"
          value={userForm.prefix}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUserForm({ ...userForm, prefix: e.target.value })
          }
        />

        <TextInputField
          label="First name"
          required
          value={userForm.first_name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUserForm({ ...userForm, first_name: e.target.value })
          }
        />
        <TextInputField
          label="Last name"
          required
          value={userForm.last_name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUserForm({ ...userForm, last_name: e.target.value })
          }
        />

        <TextInputField
          label="Suffix"
          value={userForm.suffix}
          description="Example: M.D. or Ph. D."
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUserForm({ ...userForm, suffix: e.target.value })
          }
        />
      </Card>
      <Button
        onClick={() => {
          validate()
        }}
        appearance="primary"
        fontWeight="bold"
        fontSize="0.9rem"
      >
        Save
      </Button>
    </Pane>
  )
}

export default AccountPage
