import { CONSTANTS } from '../constants'

export const badgeTooltipSelector = (category: string) => {
  let tooltipText: string
  switch (category?.toUpperCase()) {
    case CONSTANTS.POST_TYPES.TYPE.GENERAL.toUpperCase():
      tooltipText = "Non-specific, doesn’t fit other categories (e.g. need help finding a clinician in a particular geographic area)."
      break
    case CONSTANTS.POST_TYPES.TYPE.HELP.toUpperCase():
      tooltipText = "\"Urgent\" question that needs a response ASAP, typically patient-care related."
      break
    case CONSTANTS.POST_TYPES.TYPE.ASK.toUpperCase():
      tooltipText = "Non-urgent question on any topic."
      break
    case CONSTANTS.POST_TYPES.TYPE.TEACH.toUpperCase():
      tooltipText = "Share an interesting case, technique, procedure, or anything else that you think will edify the community."
      break
    case CONSTANTS.POST_TYPES.TYPE.LAY_MEDIA.toUpperCase():
      tooltipText = "Share a news or other article that you think is likely relevant for the community."
      break
    case CONSTANTS.POST_TYPES.TYPE.JOURNAL_CLUB.toUpperCase():
      tooltipText = "Share an article/abstract and why you think it is impactful."
      break
    case CONSTANTS.POST_TYPES.TYPE.PRACTICE_MANAGEMENT.toUpperCase():
      tooltipText = "Stimulate discussion by asking a relevant question or making a comment about the business of medicine."
      break
    default:
      tooltipText = ''
  }

  return tooltipText

}
