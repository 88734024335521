import { Button, majorScale, Pane, Text, TextInput } from 'evergreen-ui'
import { xor } from 'lodash'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { CONSTANTS } from '../../constants'
import {
  CreateRatingSurveyRequestPayload,
  Rating,
  SurveyStage,
} from '../../types'
import { QuickSurveyOption, SURVEY_OPTIONS } from './QuickSurvey.types'

interface QuickSurveyProps {
  type: string
  activeRating?: Rating
  userId: string
  onSubmit: (payload: CreateRatingSurveyRequestPayload) => void
  isSaving: boolean
  isSurveySaved: boolean
  surveyStage: SurveyStage
}

const QuickSurvey: FC<QuickSurveyProps> = (props: QuickSurveyProps) => {
  const [surveyStage, setSurveyStage] = useState<SurveyStage>('VISIBLE')
  const [options, setOptions] = useState<QuickSurveyOption[]>([])
  const [heading, setHeading] = useState<string>('')
  const [validationError, setValidationError] = useState<string>('')
  const [surveyPayload, setSurveyPayload] = useState<
    CreateRatingSurveyRequestPayload
  >({
    id: props.activeRating?.ratingSurvey?.id ?? '',
    rating_id: props.activeRating?.id ?? '',
    user_id: props.userId,
    rating_reasons: [],
  })

  useEffect(() => {
    selectOptionFromType(props.type)

    // clear the selected options when the type changes
    setSurveyPayload({
      ...surveyPayload,
      rating_reasons: [],
      other: '',
    })
    setValidationError('')
  }, [props.type])

  useEffect(() => {
    setSurveyStage(props.surveyStage)
  }, [props.surveyStage, props.activeRating])

  useEffect(() => {
    if (props.isSurveySaved) {
      setSurveyStage('SUCCESS')
    }
  }, [props.isSurveySaved])

  // set the correct response values based on the context
  const selectOptionFromType = (type: string) => {
    const surveyOption = SURVEY_OPTIONS.find((o) => o.type === type)
    setOptions(surveyOption?.options ?? [])
    setHeading(surveyOption?.heading ?? '')
  }

  const toggleSurveyResponse = (value: string) => {
    // toggle the value in the list of responses
    const newResponses = xor(surveyPayload.rating_reasons, [value])
    // set the responses
    setSurveyPayload({
      ...surveyPayload,
      rating_reasons: newResponses,
    })
  }

  const submitSurveyResponses = () => {
    // check to make sure we have all the data we need
    if (surveyPayload.rating_reasons.includes(CONSTANTS.RATINGS.TYPES.OTHER)) {
      if (!surveyPayload.other) {
        setValidationError('Please complete all fields.')
        return
      }
    }
    setValidationError('')

    // refetch the ID in case it has been updated
    const payload = {
      ...surveyPayload,
      id: props.activeRating?.ratingSurvey?.id ?? '',
      rating_id: props.activeRating?.id ?? '',
    }
    props.onSubmit(payload)
  }

  if (surveyStage === 'HIDDEN') {
    return null
  }
  if (surveyStage === 'VISIBLE') {
    return (
      <Pane
        className="col-xs-12 col-xl-8"
        background="gray100"
        borderRadius={5}
        padding={majorScale(2)}
      >
        {validationError ? (
          <Text display="block" color="red" marginBottom={majorScale(1)}>
            {validationError}
          </Text>
        ) : null}
        <Text display="block" marginBottom={majorScale(2)}>
          {heading}
        </Text>
        {options.map((option) => (
          <Pane display="inline-flex" key={option.code}>
            <Button
              marginRight={majorScale(1)}
              marginBottom={majorScale(1)}
              background="white"
              borderRadius={10}
              onClick={() => toggleSurveyResponse(option.code)}
              isActive={surveyPayload.rating_reasons.includes(option.code)}
              appearance={
                surveyPayload.rating_reasons.includes(option.code)
                  ? 'primary'
                  : 'default'
              }
            >
              {option.displayText}
            </Button>
            {option.code === CONSTANTS.RATINGS.TYPES.OTHER &&
            surveyPayload.rating_reasons.includes(
              CONSTANTS.RATINGS.TYPES.OTHER,
            ) ? (
              <TextInput
                width={'100%'}
                placeholder="Other"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setSurveyPayload({
                    ...surveyPayload,
                    other: e.target.value,
                  })
                }}
              ></TextInput>
            ) : null}
          </Pane>
        ))}
        <Pane
          display="flex"
          justifyContent="flex-end"
          marginTop={majorScale(2)}
        >
          <Button
            appearance="minimal"
            marginRight={majorScale(2)}
            disabled={props.isSaving}
            onClick={() => setSurveyStage('HIDDEN')}
          >
            Skip
          </Button>
          <Button
            appearance="primary"
            onClick={() => submitSurveyResponses()}
            isLoading={props.isSaving}
            disabled={surveyPayload.rating_reasons.length === 0}
          >
            <strong>Submit</strong>
          </Button>
        </Pane>
      </Pane>
    )
  } else {
    return (
      <Text color="#3366FF">Thank you for helping improve our community!</Text>
    )
  }
}

export default QuickSurvey
