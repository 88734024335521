import {
  Avatar,
  Button,
  Card,
  ChevronDownIcon,
  ChevronUpIcon,
  majorScale,
  Pane,
  Pill,
  Spinner,
  Text
} from 'evergreen-ui'
import Parser from 'html-react-parser'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Rating, UserComment } from '../../types'
import { convertToFriendlyDate } from '../../utils/convert-date'
import { generateFullName, generateUsername } from '../../utils/username'
import {
  ContentEditorDetails,
} from '../ContentEditor/ContentEditor'
import EditableContextMenu from '../EditableContextMenu/EditableContextMenu'
import PostCommentBox from '../PostCommentBox/PostCommentBox'
import RateButton from '../RateButton/RateButton'
import WysiwygEditor from '../WysiwygEditor/WysiwygEditor'
import styles from './CommentBox.module.scss'

interface CommentBoxProps {
  comment: UserComment
  isReply: boolean
  showActionPane?: boolean
  isPostingComment: boolean
  isPostSuccess: boolean
  isAuthor: boolean
  isLoggedInUser: boolean
  isSavingRating: boolean
  isEditSaveSuccess: boolean
  isSavingEdit: boolean
  postComment: (comment: string, isReply: boolean, parentId: string) => void
  rateComment: (rating: number, ratingId?: string) => void
  editComment: (comment: ContentEditorDetails) => void
}

const CommentBox: FC<CommentBoxProps> = (props: CommentBoxProps) => {
  const [showReplyContainer, setShowReplyContainer] = useState(false)
  const [rating, setRating] = useState<Rating | undefined>(undefined)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const { pathname, hash, key } = useLocation()

  // scroll to comment
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0)
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
          element.style.border = '3px solid #0F5156'
        }
      }, 0)
    }
  }, [pathname, hash, key]) // do this on route change

  useEffect(() => {
    if (props.isPostSuccess) {
      setShowReplyContainer(false)
    }
  }, [props.isPostSuccess])

  useEffect(() => {
    if (props.isEditSaveSuccess) {
      setIsEditing(false)
    }
  }, [props.isEditSaveSuccess])

  useEffect(() => {
    if (props.comment.ratings) {
      const rating = props.comment.ratings.find(
        (r) => r.comment_id === props.comment.id,
      )

      if (rating) {
        setRating(rating)
      }
    }
  }, [props.comment])

  return (
    <Pane
      id={props.comment.id}
      marginBottom={majorScale(2)}
      borderRadius={10}
    >
      <Card
        background={'white'}
        className={props.isReply ? styles.ReplyBox : styles.CommentBox}
        elevation={1}
        borderRadius={10}
        overflow="hidden"
      >
        <Pane display="flex" flexDirection="column" padding={majorScale(2)}>
          <Pane
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Pane display="flex" alignItems="center">
              <Avatar
                name={generateFullName(props.comment.user)}
                color="blue"
                marginRight={majorScale(1)}
              ></Avatar>
              <Pane display="flex" alignItems="center" justifyContent="center">
                <Text>
                  <Text display="flex" alignItems="center">
                    <Text fontWeight="bold" marginRight={majorScale(1)} fontSize={'0.9rem'}>
                      {generateUsername(props.comment.user)}
                    </Text>
                    {props.isAuthor ? <Pill color="teal">Author</Pill> : null}
                  </Text>
                  <Text fontSize={'0.8rem'} color="gray700">
                    {convertToFriendlyDate(props.comment.created_at, true)}
                  </Text>
                </Text>
              </Pane>
            </Pane>
            {/* Only show the edit menu if it is the logged in user */}
            {props.isLoggedInUser ? (
              <EditableContextMenu
                edit={() => {
                  setIsEditing(!isEditing)
                }}
              ></EditableContextMenu>
            ) : null}
          </Pane>

          <Pane
            marginTop={majorScale(2)}
            paddingLeft={majorScale(2)}
            display="flex"
            width="100%"
          >
            {isEditing ? (
              <WysiwygEditor
                initialValue={props.comment.comment}
                onUpdateContent={(newContent: string) => {
                  props.editComment({content: newContent})
                  setIsEditing(false)
                }}
              />
            ) : (
              <Text
                fontSize={'1rem'}
                whiteSpace="pre-line"
                lineHeight={1.5}
              >
                {Parser(props.comment.comment)}
              </Text>
            )}
          </Pane>
        </Pane>

        <Pane
          display={props.showActionPane === false ? 'none' : 'flex'}
          alignItems={'center'}
          justifyContent={props.isReply ? 'flex-start' : 'space-between'}
          alignContent={'flex-end'}
          background={'gray50'}
          padding={majorScale(1)}
        >
          {props.isSavingRating ? (
            <Spinner size={32} padding={5} />
          ) : (
            <Pane>
              <RateButton
                onClick={(val) => props.rateComment(val, rating?.id)}
                value={1}
                icon={<ChevronUpIcon />}
                isActiveVote={rating?.is_upvote}
                count={props.comment.meta?.upvotes ?? 0}
                activeColor={'green'}
              ></RateButton>
              <RateButton
                onClick={(val) => props.rateComment(val, rating?.id)}
                value={-1}
                icon={<ChevronDownIcon />}
                isActiveVote={rating?.is_downvote}
                count={props.comment.meta?.downvotes ?? 0}
                activeColor={'neutral'}
              ></RateButton>
            </Pane>
          )}
          <Button
            is="button"
            appearance="minimal"
            color="#3366FF"
            fontWeight="bold"
            display={props.isReply ? 'none' : 'inherit'}
            onClick={() => setShowReplyContainer(!showReplyContainer)}
          >
            Reply
          </Button>
        </Pane>
      </Card>
      <Pane marginLeft={majorScale(4)}>
        <PostCommentBox
          show={showReplyContainer}
          isPosting={props.isPostingComment}
          isPostSuccess={props.isPostSuccess}
          postComment={(comment) =>
            props.postComment(comment, true, props.comment.id)
          }
          postButtonText={'Post Reply'}
          placeholderText={'Write something...'}
        ></PostCommentBox>
      </Pane>
    </Pane>
  )
}

export default CommentBox
