import { useRoutes } from 'react-router-dom'
import App from '../App'
import ForgotPassword from '../components/ForgotPassword/ForgotPassword'
import MainLogin from '../components/MainLogin/MainLogin'
import NewPassword from '../components/NewPassword/NewPassword'
import Subscribe from '../components/Subscribe/Subscribe'
import { CONSTANTS } from '../constants'
import AccountPage from '../routes/AccountPage/AccountPage'
import CommunityPage from '../routes/CommunityPage/CommunityPage'
import PostDetailPage from '../routes/CommunityPage/PostDetailPage/PostDetailPage'
import PostListPage from '../routes/CommunityPage/PostListPage/PostListPage'
import CoursesPage from '../routes/CoursesPage/CoursesPage'
import CreatePostPage from '../routes/CreatePostPage/CreatePostPage'
import AllComments from '../routes/DashboardPage/AllComments/AllComments'
import AllLikes from '../routes/DashboardPage/AllLikes/AllLikes'
import AllNotes from '../routes/DashboardPage/AllNotes/AllNotes'
import DashboardOverview from '../routes/DashboardPage/DashboardOverview/DashboardOverview'
import DashboardPage from '../routes/DashboardPage/DashboardPage'
import LoginPage from '../routes/LoginPage/LoginPage'
import PatientVideosDetailPage from '../routes/PatientVideosDetailPage/PatientVideosDetailPage'
import PatientVideosPage from '../routes/PatientVideosPage/PatientVideosPage'
import SubscribePage from '../routes/SubscribePage/SubscribePage'

const Router = () => {
  let routes = useRoutes([
    {
      path: '/',
      element: <App />,
      children: [
        {
          path: CONSTANTS.ROUTES.DASHBOARD,
          element: <DashboardPage />,
          children: [
            {
              path: CONSTANTS.ROUTES.DASHBOARD,
              element: <DashboardOverview />,
            },
            {
              path: CONSTANTS.ROUTES.ALL_COMMENTS,
              element: <AllComments />,
            },
            {
              path: CONSTANTS.ROUTES.ALL_NOTES,
              element: <AllNotes />,
            },
            {
              path: CONSTANTS.ROUTES.ALL_LIKES,
              element: <AllLikes />,
            },
          ],
        },
        {
          path: CONSTANTS.ROUTES.PATIENT_VIDEOS,
          element: <PatientVideosPage />,
        },
        {
          path: `${CONSTANTS.ROUTES.PATIENT_VIDEOS}/:videoId`,
          element: <PatientVideosDetailPage />,
        },
        {
          path: CONSTANTS.ROUTES.COURSES,
          element: <CoursesPage flex={4} />,
        },
        {
          path: CONSTANTS.ROUTES.COMMUNITY,
          element: <CommunityPage />,
          children: [
            {
              path: `${CONSTANTS.ROUTES.COMMUNITY}`,
              element: <PostListPage />,
            },
            {
              path: `${CONSTANTS.ROUTES.COMMUNITY}/:postId`,
              element: <PostDetailPage />,
            },
          ],
        },
        {
          path: `${CONSTANTS.ROUTES.COMMUNITY}${CONSTANTS.ROUTES.WRITE_POST}`,
          element: <CreatePostPage />,
        },
        {
          path: CONSTANTS.ROUTES.ACCOUNT,
          element: <AccountPage flex={4} />,
        },
        {
          path: '*',
          element: (
            <main style={{ padding: '1rem' }}>
              <p>There's nothing here!</p>
            </main>
          ),
        },
      ],
    },
    {
      path: CONSTANTS.ROUTES.LOGIN,
      element: <LoginPage />,
      children: [
        {
          path: CONSTANTS.ROUTES.LOGIN,
          element: <MainLogin />,
          index: true,
        },
        {
          path: CONSTANTS.ROUTES.NEW_PASSWORD,
          element: <NewPassword />,
        },
        {
          path: CONSTANTS.ROUTES.NEW_PASSWORD,
          element: <NewPassword />,
        },
        {
          path: CONSTANTS.ROUTES.FORGOT_PASSWORD,
          element: <ForgotPassword />,
        },
      ],
    },
    {
      path: CONSTANTS.ROUTES.SUBSCRIBE,
      element: <SubscribePage />,
      children: [
        {
          path: CONSTANTS.ROUTES.SUBSCRIBE,
          element: <Subscribe />,
        }
      ]
    }
  ])
  return routes
}
export default Router
