import { Button, Pane } from 'evergreen-ui'
import React, { FC, useEffect, useState } from 'react'
import { getSnippet } from '../../utils/get-snippet'
import styles from './CollapsibleText.module.scss'

interface CollapsibleTextProps {
  content: string
  maxLength?: number
}

const CollapsibleText: FC<CollapsibleTextProps> = (
  props: CollapsibleTextProps,
) => {
  const [content, setContent] = useState<string>(props.content)
  const [maxLength, setMaxLength] = useState<number>(props.maxLength ?? 100)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

  useEffect(() => {
    setContent(getSnippet(props.content, maxLength))
  }, [props.content])

  useEffect(() => {
    setContent(
      isCollapsed ? getSnippet(props.content, maxLength) : props.content,
    )
  }, [isCollapsed])

  if (content.length <= maxLength) {
    return <Pane whiteSpace="pre-wrap">{content}</Pane>
  }

  return (
    <Pane className={styles.CollapsibleText}>
      <Pane whiteSpace="pre-wrap">
        {content}
        <Button
          size="small"
          onClick={() => setIsCollapsed(!isCollapsed)}
          appearance="minimal"
          fontWeight="bold"
          display="inline-block"
          padding={0}
          height="auto"
          lineHeight="auto"
          margin={0}
          color="#5C85FF"
        >
          {isCollapsed ? 'Show More' : 'Show Less'}
        </Button>
      </Pane>
    </Pane>
  )
}

export default CollapsibleText
