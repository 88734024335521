import { majorScale, Pane, Spinner, Heading, toaster } from 'evergreen-ui'
import React, { FC, useEffect, useState } from 'react'
import { CONSTANTS } from '../../constants'
import { useAppSelector } from '../../hooks'
import {
  useCreateCommentMutation,
  useEditCommentMutation,
  useGetCommentsByContextIdQuery,
  useSaveCommentRatingMutation,
} from '../../services/comments/comments.service'
import {
  CommentContextType,
  CreateCommentRequestPayload,
  EditCommentRequestPayload,
  Rating,
  SaveRatingRequestPayload,
  User,
  UserComment,
} from '../../types'
import { sortHelper } from '../../utils/sort'
import CommentBox from '../CommentBox/CommentBox'
import { ContentEditorDetails } from '../ContentEditor/ContentEditor'
import PostCommentBox from '../PostCommentBox/PostCommentBox'
import Sort from '../Sort/Sort'
import styles from './CommentsContainer.module.scss'

interface CommentsContainerProps {
  sortOptions: string[]
  defaultSort: string
  contextId: string
  contextAuthorId: string
  contextType: CommentContextType
}

const CommentsContainer: FC<CommentsContainerProps> = (
  props: CommentsContainerProps,
) => {
  const [activeSort, setActiveSort] = React.useState('')
  const [comments, setComments] = React.useState<UserComment[]>([])
  const [skipCallingApiOnLoad, setSkipCallingApi] = useState(true)

  const user: User | undefined = useAppSelector((state) => state.auth.user)

  // get comments
  const {
    data: commentData,
    isLoading: isLoadingComments,
  } = useGetCommentsByContextIdQuery(
    { context_id: props.contextId, context_type: props.contextType },
    {
      skip: skipCallingApiOnLoad,
      refetchOnMountOrArgChange: true,
    },
  )

  // Sort comments by default on load
  useEffect(() => {
    doSort(props.defaultSort)
  }, [])

  // enable the API once we have the video ID
  useEffect(() => {
    if (props.contextId !== '') {
      setSkipCallingApi(false)
    }
  }, [props.contextId])

  // sort the comments
  const doSort = (sortBy: string) => {
    setActiveSort(sortBy)
    let newComments: UserComment[] = [...comments]

    newComments = sortHelper(newComments, sortBy)

    setComments(newComments)
  }

  // create a comment
  const [
    callCreateCommentApi,
    { isLoading: isCommentPosting, isSuccess: isCommentPostSuccess },
  ] = useCreateCommentMutation()

  // edit a comment
  const [
    callEditCommentAPI,
    { isLoading: isEditingComment, isSuccess: isEditSaveSuccess },
  ] = useEditCommentMutation()

  // define the save rating API
  const [
    callCreateCommentRatingApi,
    { isLoading: isSavingRating },
  ] = useSaveCommentRatingMutation()

  // save the comments when we have new API data
  useEffect(() => {
    if (commentData) {
      setComments(commentData)
    }
  }, [commentData])

  // save a comment
  const postComment = async (
    comment: string,
    isReply: boolean,
    parentId?: string,
  ) => {
    if (user && comment) {
      const commentPaylod: CreateCommentRequestPayload = {
        is_reply: isReply,
        user_id: user?.id,
        comment: comment,
        video_id:
          props.contextType === 'COMMUNITY_POST' ? undefined : props.contextId,
        post_id: props.contextType === 'VIDEO' ? undefined : props.contextId,
        context_type: props.contextType,
        parent_id: parentId,
      }

      try {
        const result: UserComment = await callCreateCommentApi(
          commentPaylod,
        ).unwrap()
        toaster.success('Your comment has been posted.')
      } catch (exception) {
        toaster.danger('Error posting comment.')
      }
    }
  }

  // edit a comment
  const editComment = async (
    comment: UserComment,
    newContent: string,
    parentId?: string,
  ) => {
    try {
      const payload: EditCommentRequestPayload = {
        id: comment.id,
        comment: newContent,
      }
      const savedComment: UserComment = await callEditCommentAPI(
        payload,
      ).unwrap()

      if (comment.is_reply) {
        const parentIndex = comments.findIndex((c) => c.id === parentId)
        const parent = comments[parentIndex]

        if (parent && parent.replies) {
          const childIndex = parent.replies?.findIndex(
            (r) => r.id === savedComment.id,
          )
          const newChildren = Object.assign([...parent.replies], {
            [childIndex]: {
              ...parent.replies[childIndex],
              comment: savedComment.comment,
            },
          })

          const newComments = Object.assign([...comments], {
            [parentIndex]: { ...comments[parentIndex], replies: newChildren },
          })
          setComments(newComments)
        }
      } else {
        const index = comments.findIndex((c) => c.id === savedComment.id)
        const newComments = Object.assign([...comments], {
          [index]: { ...comments[index], comment: savedComment.comment },
        })
        setComments(newComments)
      }

      toaster.success('Comment updated!')
    } catch (exception) {
      toaster.danger('Error updating comment.')
    }
  }

  // rate a comment
  const rateComment = async (
    rating: number,
    comment: UserComment,
    ratingId?: string,
    reply?: UserComment,
  ) => {
    const payload: SaveRatingRequestPayload = {
      id: ratingId,
      user_id: user?.id ?? '',
      rating: rating,
      comment_id: reply?.id ?? comment.id,
    }
    try {
      updateCommentsWithRating(payload, comment, reply)
      const ratingResponse: Rating = await callCreateCommentRatingApi(
        payload,
      ).unwrap()
    } catch (exception) {
      toaster.danger('Error rating comment.')
    }
  }

  // refresh the comment counters to make the page feel responsive
  const updateCommentsWithRating = (
    ratingPaylod: SaveRatingRequestPayload,
    comment: UserComment,
    reply?: UserComment,
  ) => {
    let newComments = [...comments]
    const commentIndex = newComments.findIndex((c) => c.id === comment.id)
    let oldComment = newComments.find((c) => c.id === comment.id)

    const toUpdate: UserComment = reply ?? comment

    const isUpvote = ratingPaylod.rating === CONSTANTS.RATINGS.VALUES.UPVOTE
    const isDownvote = ratingPaylod.rating === CONSTANTS.RATINGS.VALUES.DOWNVOTE
    const upvotes = toUpdate.meta?.upvotes ?? 0
    const downvotes = toUpdate.meta?.downvotes ?? 0

    const newRating: Rating = {
      ...ratingPaylod,
      id: ratingPaylod.id ?? '',
      post_id: ratingPaylod.post_id ?? '',
      video_id: ratingPaylod.video_id ?? '',
      comment_id: ratingPaylod.comment_id ?? '',
      is_upvote: isUpvote,
      is_downvote: isDownvote,
      created_at: '',
      updated_at: '',
    }

    const finalComment: UserComment = {
      ...toUpdate,
      ratings: [newRating],
      meta: {
        upvotes: isUpvote ? upvotes + 1 : upvotes > 0 ? upvotes - 1 : 0,
        downvotes: !isUpvote
          ? downvotes + 1
          : downvotes > 0
          ? downvotes - 1
          : 0,
      },
    }

    if (reply && oldComment?.replies) {
      const replyIndex = oldComment.replies.findIndex((r) => r.id === reply.id)
      const replies = [...oldComment.replies]
      replies[replyIndex] = finalComment
      const commentWithReply = { ...oldComment, replies: replies }
      newComments[commentIndex] = commentWithReply
    } else {
      newComments[commentIndex] = finalComment
    }
    setComments(newComments)
  }

  const renderCommentsArea = () => {
    if (isLoadingComments) {
      return <Spinner></Spinner>
    }
    if (comments.length === 0) {
      return (
        <Pane
          display="flex"
          flexDirection="column"
          height="200px"
          justifyContent="center"
          alignItems="center"
        >
          <Heading
            size={500}
            className={styles.NoCommentsTitle}
            marginBottom={majorScale(1)}
          >
            No comments yet.
          </Heading>
          <Heading size={400} className={styles.NoCommentsTitle}>
            Be the first!
          </Heading>
        </Pane>
      )
    } else {
      return (
        <Pane marginTop={majorScale(2)}>
          <Pane marginY={majorScale(2)} className="col-md-12 col-lg-4 col-xl-3">
            <Sort
              options={props.sortOptions}
              selected={activeSort}
              sort={(sortBy: string) => doSort(sortBy)}
            ></Sort>
          </Pane>
          {comments.map((comment) => {
            const render = []
            render.push(
              <CommentBox
                key={comment.id}
                comment={comment}
                isReply={comment.is_reply}
                isPostingComment={isCommentPosting}
                postComment={(comment, isReply, parentId) =>
                  postComment(comment, isReply, parentId)
                }
                isPostSuccess={isCommentPostSuccess}
                isAuthor={props.contextAuthorId === comment.user_id}
                isLoggedInUser={comment.user_id === user?.id}
                rateComment={(rating: number, ratingId?: string) => {
                  rateComment(rating, comment, ratingId)
                }}
                editComment={(content: ContentEditorDetails) =>
                  editComment(comment, content.content)
                }
                isEditSaveSuccess={isEditSaveSuccess}
                isSavingRating={false}
                isSavingEdit={isEditingComment}
              ></CommentBox>,
            )

            if (comment.replies) {
              comment.replies.map((reply) => {
                render.push(
                  <CommentBox
                    key={reply.id}
                    comment={reply}
                    isReply={reply.is_reply}
                    isPostingComment={isCommentPosting}
                    postComment={(comment, isReply, parentId) =>
                      postComment(comment, isReply, parentId)
                    }
                    isPostSuccess={isCommentPostSuccess}
                    isAuthor={props.contextAuthorId === comment.user_id}
                    isLoggedInUser={comment.user_id === user?.id}
                    rateComment={(rating: number, ratingId?: string) => {
                      rateComment(rating, comment, ratingId, reply)
                    }}
                    editComment={(content: ContentEditorDetails) =>
                      editComment(reply, content.content, comment.id)
                    }
                    isEditSaveSuccess={isEditSaveSuccess}
                    isSavingRating={false}
                    isSavingEdit={isEditingComment}
                  ></CommentBox>,
                )
              })
            }

            return render
          })}
        </Pane>
      )
    }
  }

  return (
    <Pane marginBottom={majorScale(4)}>
      <PostCommentBox
        show={true}
        isPosting={isCommentPosting}
        postComment={(comment) => postComment(comment, false)}
        postButtonText={'Post Comment'}
        placeholderText={'Write something...'}
        isPostSuccess={isCommentPostSuccess}
      />
      {renderCommentsArea()}
    </Pane>
  )
}

export default CommentsContainer
