import {
  ConfirmForgotPasswordCommandOutput,
  RespondToAuthChallengeCommandOutput,
} from '@aws-sdk/client-cognito-identity-provider'
import {
  Pane,
  Heading,
  majorScale,
  TextInputField,
  Button,
  Text,
  UnorderedList,
  ListItem,
  Spinner,
  Alert,
  toaster,
} from 'evergreen-ui'
import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CONSTANTS } from '../../constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useAuth } from '../../hooks/auth.hook'
import {
  useResetForgottenPasswordMutation,
  useUpdatePasswordFromChallengeMutation,
} from '../../services/auth/auth.service'
import { setLoginSession } from '../../services/auth/auth.slice'
import {
  ForgotPasswordRequestPayload,
  LoginSession,
  NewPasswordCredentialsPayload,
  User,
} from '../../types'
import styles from './NewPassword.module.scss'

interface NewPasswordProps {}

type PasswordRouteParams = {
  username: string
  code: string
}

type ResetType = 'FORGOT_PASSWORD' | 'PASSWORD_CHALLENGE'

const NewPassword: FC<NewPasswordProps> = () => {
  const { savePasswordChallengeResponse } = useAuth()
  const loginSession: LoginSession | undefined = useAppSelector(
    (state) => state.auth.loginSession,
  )
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [resetType, setResetType] = useState<ResetType>('PASSWORD_CHALLENGE')
  const params: PasswordRouteParams = {
    code: searchParams.get('code') || '',
    username: searchParams.get('username') || '',
  }

  const [formValue, setFormValue] = useState<NewPasswordCredentialsPayload>({
    password: '',
    confirmPassword: '',
    session: '',
    username: '',
  })
  const [validationErrors, setValidationErrors] = useState({
    password: false,
    confirmPassword: false,
    mismatched: false,
  })

  useEffect(() => {
    // get the query param values
    if (params.code && params.username) {
      setResetType('FORGOT_PASSWORD')

      const sessionData: LoginSession = {
        reset_code: params.code,
        username: params.username,
      }

      dispatch(setLoginSession(sessionData))
    }
  }, [])

  const navigate = useNavigate()
  const [
    callPasswordChallengeApi,
    { isLoading: isCallPasswordApiLoading },
  ] = useUpdatePasswordFromChallengeMutation()
  const [
    callResetForgottenApi,
    { isLoading: isCallResetForgottenLoading },
  ] = useResetForgottenPasswordMutation()

  const doPasswordUpdate = async (e?: FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault()
    }
    const isValid = validate()

    if (isValid) {
      try {
        if (resetType === 'PASSWORD_CHALLENGE') {
          const postData: NewPasswordCredentialsPayload = { ...formValue }
          postData.session = loginSession?.sessionId || ''
          postData.username = loginSession?.username ?? ''

          let result: RespondToAuthChallengeCommandOutput = await callPasswordChallengeApi(
            postData,
          ).unwrap()

          if (result.AuthenticationResult?.AccessToken) {
            savePasswordChallengeResponse(result)
            navigate(CONSTANTS.ROUTES.DASHBOARD)
          }
        } else if (resetType === 'FORGOT_PASSWORD') {
          const postData: ForgotPasswordRequestPayload = {
            code: loginSession?.reset_code || '',
            username: loginSession?.username || '',
            password: formValue.password,
            confirmPassword: formValue.confirmPassword,
          }

          let result: ConfirmForgotPasswordCommandOutput = await callResetForgottenApi(
            postData,
          ).unwrap()

          if (result.$metadata.httpStatusCode === 200) {
            toaster.success('Your password has been updated. Please log in.')
            navigate(CONSTANTS.ROUTES.LOGIN)
          }
        }
      } catch (exception) {}
    }
  }

  const validate = () => {
    const errors = {
      password: false,
      confirmPassword: false,
      mismatched: false,
    }

    if (!formValue.confirmPassword) {
      errors.confirmPassword = true
    }
    if (!formValue.password) {
      errors.password = true
    }
    if (formValue.password !== formValue.confirmPassword) {
      errors.mismatched = true
    }

    setValidationErrors(errors)

    return Object.values(errors).every((value) => value === false)
  }

  return (
    <Pane width="70%">
      <Heading size={800} marginBottom={majorScale(4)}>
        Welcome!
      </Heading>
      {validationErrors.mismatched ? (
        <Alert
          intent="danger"
          title="Passwords do not match."
          marginBottom={majorScale(2)}
        >
          Please try again.
        </Alert>
      ) : null}
      <Heading size={900}>
        {resetType === 'PASSWORD_CHALLENGE'
          ? 'First things first'
          : 'Reset Password'}
      </Heading>
      <Heading size={800} color="#696F8C" marginBottom={majorScale(4)}>
        Please update your password
      </Heading>
      <Text display={'block'} marginBottom={majorScale(2)}>
        Your new password should match the following guidelines:
      </Text>
      <UnorderedList marginBottom={majorScale(4)}>
        <ListItem>At least 8 characters</ListItem>
        <ListItem>At least one uppercase and one lowercase character</ListItem>
        <ListItem>At least one number</ListItem>
        <ListItem>
          At least one special character: (^ $ * . [ ] {} ( ) ? - " ! @ # %
          &amp; / \ , &#62; &#60; ' : ; | _ ~ ` + =)
        </ListItem>
      </UnorderedList>
      <form onSubmit={(e) => doPasswordUpdate(e)}>

        <TextInputField
          id="password"
          label="Password"
          required
          placeholder="Password"
          type={'password'}
          isInvalid={validationErrors.password}
          name="password"
          value={formValue.password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValue({ ...formValue, password: e.target.value })
          }
        ></TextInputField>
        <TextInputField
          id="confirmPassword"
          label="Confirm New Password"
          required
          placeholder="Confirm New Password"
          type={'password'}
          value={formValue.confirmPassword}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValue({ ...formValue, confirmPassword: e.target.value })
          }
          name="confirmPassword"
          isInvalid={validationErrors.confirmPassword}
        ></TextInputField>
        <Button
          marginBottom={majorScale(4)}
          fontSize="14px"
          type="submit"
          disabled={isCallPasswordApiLoading}
        >
          {isCallPasswordApiLoading || isCallResetForgottenLoading ? (
            <Spinner size={majorScale(2)}></Spinner>
          ) : (
            <Text>Submit</Text>
          )}
        </Button>
      </form>
    </Pane>
  )
}

export default NewPassword
