import {
  Card,
  Text,
  majorScale,
  minorScale,
  Pane,
  Strong,
  Button,
  CaretRightIcon,
  InboxIcon,
  Pill,
  ThumbsUpIcon,
  Tooltip,
} from 'evergreen-ui'
import React, { FC, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { CONSTANTS } from '../../constants'
import { WidgetDetails } from '../../utils/generate-widget-details'
import CollapsibleText from '../CollapsibleText/CollapsibleText'
import ContentEditor, {
  ContentEditorDetails,
} from '../ContentEditor/ContentEditor'
import EditableContextMenu from '../EditableContextMenu/EditableContextMenu'
import styles from './WidgetDetailCard.module.scss'

interface WidgetDetailCardProps {
  details: WidgetDetails
  editable?: boolean
  edit?: (details: ContentEditorDetails) => void
  isEditSaveSuccess?: boolean
  isSaving?: boolean
  hasTitle?: boolean
}

const WidgetDetailCard: FC<WidgetDetailCardProps> = (
  props: WidgetDetailCardProps,
) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    if (props.isEditSaveSuccess) {
      setIsEditing(!props.isEditSaveSuccess)
    }
  }, [props.isEditSaveSuccess])

  return (
    <Card
      key={props.details.id}
      marginBottom={majorScale(2)}
      border
      padding={majorScale(2)}
      borderRadius={5}
      display="flex"
      justifyContent="center"
      flexDirection="column"
      gap={minorScale(1)}
      hoverElevation={2}
    >
      {isEditing ? (
        <ContentEditor
          initialValue={{
            content: props.details.content,
            title: props.details.title,
          }}
          edit={(content) => (props.edit ? props.edit(content) : null)}
          isSaving={props.isSaving ?? false}
          cancel={() => setIsEditing(false)}
          hasTitle={props.hasTitle}
        ></ContentEditor>
      ) : (
        <Pane>
          <Pane display="flex" justifyContent="space-between">
            <Pane display="flex" flexDirection="column">
              <Text fontSize={'0.8rem'} display="block" color="#8f95b2">
                {props.details.created_at}
              </Text>
              {props.details.type !== CONSTANTS.WIDGET_DETAILS.TYPES.COMMENT ? (
                <Pane>
                  <Strong fontSize={'1rem'} color="#101840">
                    {props.details.title}
                  </Strong>
                </Pane>
              ) : null}
            </Pane>
            {props.editable ? (
              <EditableContextMenu
                edit={() => {
                  setIsEditing(!isEditing)
                }}
              ></EditableContextMenu>
            ) : null}
          </Pane>
          <Pane fontSize={'1rem'} color="#474d66" marginTop={majorScale(1)}>
            <CollapsibleText content={props.details.content} />
          </Pane>
        </Pane>
      )}

      {props.details.type === CONSTANTS.WIDGET_DETAILS.TYPES.COMMENT ? (
        <Pane
          display="flex"
          gap={10}
          alignItems="center"
          marginTop={majorScale(1)}
        >
          <Tooltip content="Upvotes">
            <Pill color="blue" display="flex" alignItems="center">
              <ThumbsUpIcon marginRight={minorScale(1)} size={9} />
              {props.details.original.meta?.upvotes}
            </Pill>
          </Tooltip>
          {!props.details.original.is_reply ? (
            <Tooltip content="Replies">
              <Pill color="neutral" display="flex" alignItems="center">
                <InboxIcon marginRight={minorScale(1)} size={9} />
                {props.details.original.meta?.replies_count}
              </Pill>
            </Tooltip>
          ) : null}
        </Pane>
      ) : null}
      <Pane marginTop={majorScale(1)} paddingTop={minorScale(1)}>
        <Button
          iconAfter={<CaretRightIcon size={majorScale(2)} />}
          size={'small'}
          is={NavLink}
          to={props.details.route}
          height="auto"
        >
          <Strong fontSize={'0.7rem'} whiteSpace="break-spaces" flexWrap="wrap">
            {props.details.button_text}
          </Strong>
        </Button>
      </Pane>
    </Card>
  )
}

export default WidgetDetailCard
