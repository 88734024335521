import { CONSTANTS } from '../constants'

type BadgeColor =
  | 'automatic'
  | 'neutral'
  | 'blue'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'teal'
  | 'purple'

export const badgeColorSelector = (category: string) => {
  let color: BadgeColor
  switch (category?.toUpperCase()) {
    case CONSTANTS.POST_TYPES.TYPE.GENERAL.toUpperCase():
      color = 'blue'
      break
    case CONSTANTS.POST_TYPES.TYPE.HELP.toUpperCase():
      color = 'red'
      break
    case CONSTANTS.POST_TYPES.TYPE.ASK.toUpperCase():
      color = 'green'
      break
    case CONSTANTS.POST_TYPES.TYPE.TEACH.toUpperCase():
      color = 'yellow'
      break
    case CONSTANTS.POST_TYPES.TYPE.LAY_MEDIA.toUpperCase():
      color = 'purple'
      break
    case CONSTANTS.POST_TYPES.TYPE.JOURNAL_CLUB.toUpperCase():
      color = 'teal'
      break
    case CONSTANTS.POST_TYPES.TYPE.PRACTICE_MANAGEMENT.toUpperCase():
      color = 'orange'
      break
    default:
      color = 'neutral'
  }
  return color
}
