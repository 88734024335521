import { RateTabGroupOption } from '../../../types'

export const PostDetailRateOptions: RateTabGroupOption[] = [
  {
    id: 1,
    value: 1,
    displayText: 'Yes',
    badgeText: '',
  },
  {
    id: 2,
    value: -1,
    displayText: 'No',
    badgeText: '',
  },
]