import {
  Pane,
  majorScale,
  Button,
  ChevronLeftIcon,
  Heading,
} from 'evergreen-ui'
import React, { FC, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import NotesWidget from '../../../components/DashboardWidgets/NotesWidget/NotesWidget'
import SearchBox from '../../../components/SearchBox/SearchBox'
import { CONSTANTS } from '../../../constants'
import { useAppSelector } from '../../../hooks'
import { User } from '../../../types'
import { generateSubpageTitle } from '../../../utils/generate-page-title'
import styles from './AllNotes.module.scss'

interface AllNotesProps {}

const AllNotes: FC<AllNotesProps> = () => {
  let navigate = useNavigate()

  const [search, setSearch] = useState('')

  const user: User | undefined = useAppSelector((state) => state.auth.user)
  const title = generateSubpageTitle(CONSTANTS.TITLES.DASHBOARD, 'Notes')

  // Navigate back to patient videos page
  const goBack = () => {
    navigate(`${CONSTANTS.ROUTES.DASHBOARD}`)
  }

  return (
    <Pane
      flex={4}
      className={styles.AllNotes}
      data-testid="AllNotesF"
      padding={majorScale(4)}
      background="tint2"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Button
        appearance="secondary"
        onClick={() => goBack()}
        marginBottom={majorScale(3)}
        iconBefore={ChevronLeftIcon}
      >
        Back
      </Button>
      <Pane marginY={majorScale(4)}>
        <Heading size={800} marginBottom={majorScale(2)}>
          All notes
        </Heading>
        <Pane
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          justifyContent="space-between"
          gap={majorScale(3)}
        >
          <Pane display="flex">
            <SearchBox
              doSearch={(term) => setSearch(term)}
              width="100%"
              placeholder="Search"
            ></SearchBox>
          </Pane>
          <Pane flex="1">
            <NotesWidget user={user} search={search}></NotesWidget>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}

export default AllNotes
