import {
  Dialog,
  EditIcon,
  IconButton,
  Pane,
  Textarea,
  Text,
  Tooltip,
  majorScale,
  InfoSignIcon,
  toaster,
  Strong,
} from 'evergreen-ui'
import React, { ChangeEvent, FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { useCreateNoteMutation } from '../../services/notes/notes.service'
import { Note, User } from '../../types'
import TitleTextBox from '../TitleTextBox/TitleTextBox'
import styles from './QuickNote.module.scss'

interface QuickNoteProps {
  show: boolean
}

const emptyNote: Note = {
  title: '',
  note: '',
  route: '',
  route_title: '',
  tags: '',
  user_id: '',
  created_at: '',
}

const QuickNote: FC<QuickNoteProps> = (props: QuickNoteProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [note, setNote] = useState<Note>(emptyNote)
  const location = useLocation()
  const user: User | undefined = useAppSelector((state) => state.auth.user)

  // define the note API
  const [
    callCreateNoteApi,
    { isLoading: isSavingNote, isSuccess: isSaveSuccess },
  ] = useCreateNoteMutation()

  const saveNote = async () => {
    const payload: Note = {
      ...note,
      route: location.pathname,
      route_title: document.title,
      user_id: user?.id ?? '',
    }

    try {
      await callCreateNoteApi(payload).unwrap()
      toaster.success('Your note has been saved.')
      onClose()
    } catch (exception) {
      toaster.danger('Error saving your note.')
    }
  }

  const onOpen = () => {
    setNote(emptyNote)
  }

  const onClose = () => {
    setShowDialog(false)
    setNote(emptyNote)
  }

  if (!props.show) {
    return null
  }
  return (
    <Pane>
      <Dialog
        isShown={showDialog}
        title={
          <Pane>
            <Strong display="block" marginBottom={majorScale(1)}>
              Quick note
            </Strong>
            <TitleTextBox
              value={note.title}
              setTitle={(title: string) => {
                setNote({ ...note, title })
              }}
              fontSize={'1.2rem'}
              placeholder="Tap here to add a note title (required)"
            ></TitleTextBox>
          </Pane>
        }
        onCloseComplete={() => onClose()}
        onOpenComplete={() => onOpen()}
        shouldCloseOnOverlayClick={false}
        hasClose={false}
        confirmLabel={'Save note'}
        isConfirmDisabled={!note.title || !note.note}
        isConfirmLoading={isSavingNote}
        onConfirm={() => saveNote()}
      >
        <Textarea
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setNote({ ...note, note: e.target.value })
          }
          placeholder="Write your note content here..."
          width="100%"
        ></Textarea>
        <Pane marginTop={majorScale(2)} display="flex" alignItems="center">
          <InfoSignIcon
            color="#3366FF"
            marginRight={majorScale(1)}
          ></InfoSignIcon>
          <Text size="small">
            Capture a note or key moment from anywhere and revisit it at any
            time.
          </Text>
        </Pane>
      </Dialog>
      <Pane
        className={styles.QuickNote}
        data-testid="QuickNote"
        position="fixed"
        zIndex="2"
        bottom={20}
        right={20}
        cursor={'pointer'}
        // ref={draggableRef}
      >
        <Tooltip content={'Quick Note'}>
          <IconButton
            borderRadius="50%"
            icon={<EditIcon color="#3366FF" />}
            size={'large'}
            appearance="minimal"
            background="white"
            border="2px solid #3366FF"
            onClick={() => setShowDialog(true)}
          ></IconButton>
        </Tooltip>
      </Pane>
    </Pane>
  )
}

export default QuickNote
