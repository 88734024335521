import { Button, Pane, Spinner, majorScale } from 'evergreen-ui';
import React, { FC, useEffect } from 'react';
import WysiwygEditor from '../WysiwygEditor/WysiwygEditor';

interface PostCommentBoxProps {
  show: boolean
  isPosting: boolean
  postComment: (comment: string) => void
  postButtonText: string
  placeholderText: string
  isPostSuccess: boolean
}

const PostCommentBox: FC<PostCommentBoxProps> = (
  props: PostCommentBoxProps,
) => {
  const [comment, setComment] = React.useState('')

  useEffect(() => {
    if (props.isPostSuccess) {
      setComment('')
    }
  }, [props.isPostSuccess])

  if (!props.show) {
    return null
  }

  return (
    <Pane marginY={majorScale(4)}>
      <Pane textAlign="right">
        <WysiwygEditor
          initialValue={comment}
          onUpdateContent={(newContent: string) => setComment(newContent)}
        />
        <Pane marginBottom={majorScale(2)}></Pane>
        <Button
          marginLeft="auto"
          disabled={props.isPosting || comment === ''}
          type="button"
          appearance="primary"
          onClick={() => {
            props.postComment(comment)
          }}
          color="white"
        >
          {props.isPosting ? (
            <Spinner size={majorScale(2)} color="white"></Spinner>
          ) : (
            <strong>{props.postButtonText}</strong>
          )}
        </Button>
      </Pane>
    </Pane>
  )
}

export default PostCommentBox
