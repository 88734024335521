import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider'
import { Image, majorScale, Pane } from 'evergreen-ui'
import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { CONSTANTS } from '../../constants'
import { useAppSelector } from '../../hooks'
import { generatePageTitle } from '../../utils/generate-page-title'
interface LoginPageProps {}

const LoginPage: FC<LoginPageProps> = () => {
  const title = generatePageTitle(CONSTANTS.TITLES.LOGIN)

  let navigate = useNavigate()

  const auth: AuthenticationResultType | undefined = useAppSelector(
    (state) => state.auth.authResult,
  )
  useEffect(() => {
    if (auth) {
      navigate(CONSTANTS.ROUTES.DASHBOARD)
    }
  }, [])

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Pane
        flex="2"
        padding={majorScale(10)}
        display="flex"
        alignContent="center"
        justifyContent="center"
      >
        <Outlet></Outlet>
      </Pane>
      <Pane
        background="blue100"
        flex="1"
        display="flex"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Image src="/login_image.svg" maxWidth="100%" width="80%"></Image>
      </Pane>
    </Pane>
  )
}
export default LoginPage
