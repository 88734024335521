
import JoditEditor from 'jodit-react';
import { CONSTANTS } from '../../constants';

interface WysiwygEditorProps {
  initialValue?: string
  onUpdateContent?: (content: string) => void
  placeholder?: string
  allowUrls?: boolean
}

const WysiwygEditor = (props: WysiwygEditorProps) => {

  const joditConfig = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: props.placeholder ?? 'Your content here...',
    cleanHTML: {
      allowTags: CONSTANTS.JODIT.ALLOWED_TAGS
    },
    style: {
      textAlign: 'left'
    },
    uploader: {
      url: 'none'
    },
    removeButtons: ['source', 'about', 'video', 'file', 'image', 'print', 'copyformat']
  };

  return (
    <JoditEditor
      value={props.initialValue ?? ''}
      onBlur={props.onUpdateContent}
      config={joditConfig}
    />
  );
}

export default WysiwygEditor;