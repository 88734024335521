import {
  Pane,
  majorScale,
  Heading,
  Button,
  ChevronLeftIcon,
  Card,
  Text,
  Spinner,
  toaster,
} from 'evergreen-ui'
import React, { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import MultiFileUploader from '../../components/MultiFileUploader/MultiFileUploader'
import PostContentTextArea from '../../components/PostContentTextArea/PostContentTextArea'
import TagSelector from '../../components/TagSelector/TagSelector'
import TitleTextBox from '../../components/TitleTextBox/TitleTextBox'
import { CONSTANTS } from '../../constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useCreatePostMutation } from '../../services/posts/posts.service'
import { useUploadFilesMutation } from '../../services/uploads/uploads.service'
import {
  CreatePostRequestPayload,
  CreatePostResponsePayload,
  Tag,
  User,
  UserUploadPostRequest,
} from '../../types'
import { generatePageTitle } from '../../utils/generate-page-title'
import styles from './CreatePostPage.module.scss'
import {
  clearCreatePostState,
  setPostContent,
  setPostTags,
  setPostTitle,
} from './CreatePostPage.slice'

interface CreatePostPageProps {}

const CreatePostPage: FC<CreatePostPageProps> = () => {
  const title = generatePageTitle(CONSTANTS.TITLES.WRITE_POST)

  let [isSavingPageContent, setIsSavingPageContent] = useState(false)
  let [isValid, setIsValid] = useState(false)
  let [postUploads, setUploads] = useState<File[]>([])
  let navigate = useNavigate()
  let dispatch = useAppDispatch()

  // get values from state
  const postTitle: string = useAppSelector(
    (state) => state.createPostPage.title,
  )
  const postContent: string = useAppSelector(
    (state) => state.createPostPage.content,
  )
  const tags: Tag[] = useAppSelector((state) => state.createPostPage.tags)
  const user: User | undefined = useAppSelector((state) => state.auth.user)

  // define the post API
  const [
    callCreatePostApi,
    { isLoading: isPosting, isSuccess: isPostSuccess },
  ] = useCreatePostMutation()

  // define the uploads API
  const [
    callUploadFilesApi,
    { isLoading: isUploadingFiles, isSuccess: isFilesUploadSuccess },
  ] = useUploadFilesMutation()

  // Navigate back to patient videos page
  const goBack = () => {
    navigate(`${CONSTANTS.ROUTES.COMMUNITY}`)
  }

  const createPost = async () => {
    const payload: CreatePostRequestPayload = {
      title: postTitle,
      content: postContent,
      user_id: user?.id ?? '',
      tags: tags.flatMap((t) => t.id) ?? [],
    }
    setIsSavingPageContent(true)

    try {
      // first create the post
      const post: CreatePostResponsePayload = await callCreatePostApi(
        payload,
      ).unwrap()

      if (postUploads.length > 0) {
        // then upload the attachments, if any
        const formData = new FormData()
        postUploads.map((u) => formData.append('uploads', u))

        const uploadPayload: UserUploadPostRequest = {
          postId: post.id,
          files: formData,
        }
        await callUploadFilesApi(uploadPayload).unwrap()
      }

      toaster.success('Your post has been created.')
      dispatch(clearCreatePostState())
      navigate(`${CONSTANTS.ROUTES.COMMUNITY}/${post.id}`)
    } catch (exception) {
      toaster.danger('Error creating your post.')
    }
    setIsSavingPageContent(false)
  }

  const validatePost = () => {
    let isValid = false
    if (
      postTitle?.trim().length > 0 &&
      postContent?.trim().length > 0 &&
      tags.length
    ) {
      isValid = true
    }
    setIsValid(isValid)
  }

  // every time content changes, check to see if submit button should be enabled
  useEffect(() => {
    validatePost()
  }, [postTitle, postContent, tags])

  return (
    <Pane
      flex={4}
      data-testid="CreatePostPage"
      padding={majorScale(4)}
      background="tint2"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Pane marginBottom={majorScale(2)}>
        <Heading size={700} className="crumb parent" onClick={() => goBack()}>
          Community
        </Heading>
        <Heading size={900} className="crumb child">
          Create a Post
        </Heading>
      </Pane>
      <Button
        appearance="secondary"
        onClick={() => goBack()}
        marginBottom={majorScale(3)}
      >
        <ChevronLeftIcon marginRight={majorScale(1)}></ChevronLeftIcon>Back
      </Button>
      <Card
        border="default"
        padding={majorScale(2)}
        display="flex"
        flexDirection="column"
        background="white"
        borderRadius={10}
      >
        <Heading>Pick a category</Heading>

        <Pane marginTop={majorScale(2)}>
          <TagSelector
            contextType="COMMUNITY_POST"
            onSelect={(tags) => {
              dispatch(setPostTags(tags))
            }}
            selected={tags}
          ></TagSelector>
        </Pane>
      </Card>
      <Card
        border="default"
        padding={majorScale(2)}
        display="flex"
        flexDirection="column"
        background="white"
        borderRadius={10}
        marginY={majorScale(4)}
      >
        <TitleTextBox
          setTitle={(title) => {
            dispatch(setPostTitle(title))
          }}
          value={postTitle}
          placeholder={'Post Title'}
        ></TitleTextBox>
        <Pane marginTop={majorScale(2)}>
          <PostContentTextArea
            onChange={(content) => {
              dispatch(setPostContent(content))
            }}
            value={postContent}
          ></PostContentTextArea>
        </Pane>
      </Card>
      <Card
        border="default"
        padding={majorScale(2)}
        display="flex"
        flexDirection="column"
        background="white"
        borderRadius={10}
        marginBottom={majorScale(2)}
      >
        <Heading>Upload images</Heading>
        <Pane marginTop={majorScale(2)}>
          <MultiFileUploader
            onChange={(files: File[]) => setUploads(files)}
          ></MultiFileUploader>
        </Pane>
      </Card>
      <Button
        appearance="primary"
        fontWeight="bold"
        fontSize="1rem"
        marginTop={majorScale(2)}
        size="medium"
        onClick={() => createPost()}
        disabled={!isValid}
        isLoading={isSavingPageContent}
      >
        Create post
      </Button>
    </Pane>
  )
}

export default CreatePostPage
