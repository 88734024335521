import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { CONSTANTS } from '../constants'
import { toaster } from 'evergreen-ui'
import { HttpErrorResponse } from '../types'
import { RootState } from '../store'
import { clearAuthState, setAuthResult } from '../services/auth/auth.slice'
import { InitiateAuthCommandOutput } from '@aws-sdk/client-cognito-identity-provider'
import { Mutex } from 'async-mutex'

const mutex = new Mutex()

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const auth = (getState() as RootState).auth.authResult

    // If we have a token set in state, let's assume that we should be passing it.
    if (auth) {
      headers.set('Authorization', `${auth.AccessToken}`)
    }

    return headers
  },
})

export const baseQueryConfigured: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  if (result.error) {
    // try to refresh the token if it's unauthorized
    const authData = (api.getState() as RootState).auth.authResult
    if (
      result.error.status === 401 &&
      authData?.AccessToken &&
      authData?.RefreshToken
    ) {
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
          // try to get a new token
          const refreshResult = await baseQuery(
            {
              url: CONSTANTS.API.REFRESH_TOKEN,
              method: 'POST',
              body: {
                id: (api.getState() as RootState).auth.user?.id,
                refresh_token: (api.getState() as RootState).auth.authResult
                  ?.RefreshToken,
              },
            },
            api,
            extraOptions,
          )

          const output = refreshResult.data as InitiateAuthCommandOutput

          if (output?.AuthenticationResult) {
            // store the new token
            api.dispatch(
              setAuthResult({
                ...(api.getState() as RootState).auth.authResult,
                ...output.AuthenticationResult,
              }),
            )
            // retry the initial query
            result = await baseQuery(args, api, extraOptions)
          } else {
            api.dispatch(clearAuthState())
            toaster.danger(
              "You've been logged out due to inactivity. Please log in again.",
            )
          }
        } catch (exception) {
          console.log('Error refreshing token: ', exception)
        } finally {
          // release must be called once the mutex should be released again.
          release()
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock()
        result = await baseQuery(args, api, extraOptions)
      }
    } else {
      // if any other type of error, let's flash an error on the screen
      let errorResult = result.error.data as HttpErrorResponse
      let errorDescription = errorResult?.message ?? 'Something went wrong'

      toaster.danger(errorDescription)
    }
  }
  return result
}
