import {
  Pane,
  TextInput,
  Text,
  Textarea,
  TextareaProps,
  majorScale,
} from 'evergreen-ui'
import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useState,
} from 'react'
import { CONSTANTS } from '../../constants'
import styles from './TitleTextBox.module.scss'

interface TitleTextBoxProps extends TextareaProps {
  setTitle: (title: string) => void
  value: string
}

const TitleTextBox: FC<TitleTextBoxProps> = (props: TitleTextBoxProps) => {
  const { setTitle, value, ...textAreaProps } = props
  const [charCount, setCharCount] = useState(0)

  useEffect(() => {
    setCharCount(props.value?.length)
  }, [props.value])

  const updateTitle = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const title = event.target.value
    if (title.length <= CONSTANTS.LIMITS.MAX_TITLE_LENGTH) {
      event.target.style.height = '0px'
      event.target.style.height = `${event.target.scrollHeight}px`
      props.setTitle(title)
    }
  }

  return (
    <Pane display="flex" flexDirection="row" alignItems="center" width="100%">
      <Textarea
        {...textAreaProps}
        placeholder={props.placeholder ?? 'Post Title'}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => updateTitle(e)}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            e.preventDefault()
          }
        }}
        value={props.value}
        resize="none"
        rows={1}
        fontSize={props.fontSize ?? '2.3rem'}
        lineHeight={props.fontSize ?? '2.3rem'}
        width={props.width ?? '100%'}
        border={props.border ?? 'none'}
        fontWeight={props.fontWeight ?? 'bold'}
        borderRadius={props.borderRadius ?? 0}
        minHeight={props.minHeight ?? 'auto'}
        margin={props.margin ?? 0}
        padding={props.padding ?? 0}
        borderBottom={props.borderBottom ?? '1px dotted #7A8A99'}
        paddingBottom={props.paddingBottom ?? '2px'}
      ></Textarea>

      <Text display="inline-flex" marginLeft={majorScale(1)} color="#7A8A99">
        {charCount}/{CONSTANTS.LIMITS.MAX_TITLE_LENGTH}
      </Text>
    </Pane>
  )
}

export default TitleTextBox
