import { Card, Heading, majorScale, Pane, Paragraph } from 'evergreen-ui'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import CommentsWidget from '../../../components/DashboardWidgets/CommentsWidget/CommentsWidget'
import NotesWidget from '../../../components/DashboardWidgets/NotesWidget/NotesWidget'
import RatingsWidget from '../../../components/DashboardWidgets/RatingsWidget/RatingsWidget'
import { CONSTANTS } from '../../../constants'
import { useAppSelector } from '../../../hooks'
import { User } from '../../../types'
import { generatePageTitle } from '../../../utils/generate-page-title'
import styles from './DashboardOverview.module.scss'

interface DashboardOverviewProps {}

const DashboardOverview: FC<DashboardOverviewProps> = (
  props: DashboardOverviewProps,
) => {
  const user: User | undefined = useAppSelector((state) => state.auth.user)

  const title = generatePageTitle(CONSTANTS.TITLES.DASHBOARD)
  return (
    <Pane
      flex={4}
      className={styles.DashboardOverview}
      data-testid="DashboardOverview"
      padding={majorScale(4)}
      background="tint2"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Card
        border
        padding={majorScale(2)}
        display="flex"
        flexDirection="column"
        background="white"
        elevation={1}
      >
        <Heading size={800} marginBottom={majorScale(1)}>
          Welcome, {user?.first_name}!
        </Heading>
        {/* <Paragraph marginBottom={majorScale(1)}>
          We are so glad you're here and are so excited to take you on this
          journey to transform our industry.
        </Paragraph>*/} 
      </Card>
      <Pane marginY={majorScale(4)}>
        <Heading size={800} marginBottom={majorScale(2)}>
          My Activity
        </Heading>
        <Pane
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          gap={majorScale(3)}
        >
          <Pane flex="1">
            <CommentsWidget
              user={user}
              limit={3}
              title="My recent comments"
              showHeading={true}
            ></CommentsWidget>
          </Pane>
          <Pane flex="1">
            <NotesWidget
              user={user}
              limit={3}
              title="My recent notes"
              showHeading={true}
            ></NotesWidget>
          </Pane>
          <Pane flex="1">
            <RatingsWidget
              user={user}
              limit={3}
              title="My recent likes"
              showHeading={true}
            ></RatingsWidget>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}

export default DashboardOverview
