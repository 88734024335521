import { CONSTANTS } from '../constants'

export const generatePageTitle = (title: string) =>
  `${title} | ${CONSTANTS.TITLES.BASE}`

export const generateCleanPageTitle = (title: string) =>
  title.replace(` | ${CONSTANTS.TITLES.BASE}`, '')

export const generateSubpageTitle = (parent: string, child: string) =>
  `${parent} » ${child}`
