import { createSlice } from '@reduxjs/toolkit'

// Define a type for the slice state
interface PatientVideosDetailState {}

// Define the initial state using that type
const initialState: PatientVideosDetailState = {}

export const patientVideosDetailSlice = createSlice({
  name: 'patientVideosDetailSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
})

export const {} = patientVideosDetailSlice.actions

export default patientVideosDetailSlice.reducer
