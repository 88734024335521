import axios from 'axios'
import { toaster } from 'evergreen-ui'
import { CONSTANTS } from '../constants'

export const initializeAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  axios.defaults.withCredentials = true

  // axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
  axios.defaults.headers.post['Content-Type'] = 'application/json'

  axios.interceptors.request.use(
    (request) => {
      // Edit request config
      return request
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      toaster.danger('Something went wrong', {
        description: error?.message,
      })
      return Promise.reject(error)
    },
  )
}
