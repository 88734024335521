import { Card, Heading, majorScale, Pane, Spinner, Strong } from 'evergreen-ui'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CONSTANTS } from '../../constants'
import { useGetTagsByContextTypeQuery } from '../../services/tags/tags.service'
import { Post, Tag } from '../../types'
import ComboFilter from '../ComboFilter/ComboFilter'
import FilterButton from '../FilterButton/FilterButton'
import PostBox from '../PostBox/PostBox'
import SearchBox from '../SearchBox/SearchBox'
import Sort from '../Sort/Sort'
import TagSelector from '../TagSelector/TagSelector'
import styles from './PostsContainer.module.scss'

interface PostsContainerProps {
  posts: Post[]
  doSort: (sortBy: string) => void
  doFilter: (filterBy: Tag[]) => void
  doSearch: (searchBy: string) => void
  onPostItemClick: (postId: string) => void
  activeSort: string
  sortOptions: string[]
  activeSearch: string
  activeFilter: Tag[]
  isLoading: boolean
}

const PostsContainer: FC<PostsContainerProps> = (
  props: PostsContainerProps,
) => {
  let params = useParams()
  let navigate = useNavigate()

  // the active post ID
  const [postId, setPostId] = useState<string>('')

  // listen to the route to make sure we get
  // the route param required to make API call
  useEffect(() => {
    if (params.postId) {
      setPostId(params.postId)
    } else {
      setPostId('')
    }
  }, [params.postId])

  const doSort = (sortBy: string) => {
    props.doSort(sortBy)
  }

  // API call to get the video data
  const { data: tags, isFetching } = useGetTagsByContextTypeQuery(
    CONSTANTS.CONTEXT_TYPES.COMMUNITY_POST,
    {
      refetchOnMountOrArgChange: 300,
    },
  )

  const renderPosts = () => {
    if (props.isLoading || isFetching) {
      return <Spinner />
    }
    if (props.posts.length === 0) {
      return (
        <Pane
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Heading
            size={500}
            className={styles.NoPostsTitle}
            marginTop={majorScale(1)}
          >
            No posts.
          </Heading>
        </Pane>
      )
    } else {
      return (
        <Pane>
          {props.posts.map((post) => (
            <Pane
              display="block"
              marginBottom={majorScale(2)}
              key={post.id}
              backgroundColor="white"
            >
              <PostBox
                key={post.id}
                post={post}
                onClick={(id) => props.onPostItemClick(id)}
                isActive={postId === post.id}
              ></PostBox>
            </Pane>
          ))}
        </Pane>
      )
    }
  }
  return (
    <Pane display="flex" flex="1" flexDirection="column" gap={majorScale(2)}>
      <Card
        border="default"
        padding={majorScale(2)}
        display="flex"
        background="white"
        borderRadius={10}
        flexDirection="column"
        justifyContent="center"
        elevation={2}
        position="sticky"
      >
        <SearchBox
          width={'100%'}
          placeholder="Search posts"
          doSearch={(searchString: string) => props.doSearch(searchString)}
          marginBottom={majorScale(2)}
          value={props.activeSearch}
        ></SearchBox>
        <Pane>
          <Pane display="flex" flexDirection="column" gap={majorScale(1)}>
            <Heading size={400}>Filter:</Heading>
            <TagSelector
              contextType="COMMUNITY_POST"
              onSelect={(filterBy: Tag[]) => {
                props.doFilter(filterBy)
              }}
              selected={props.activeFilter}
              showClearButton={true}
            ></TagSelector>
          </Pane>
        </Pane>
      </Card>
      <Pane className="col-xs-12 col-md-3">
        <Sort
          options={props.sortOptions}
          selected={props.activeSort}
          sort={(sortBy: string) => doSort(sortBy)}
          appearance="minimal"
          title={'Sort:'}
        ></Sort>
      </Pane>
      <Card>{renderPosts()}</Card>
    </Pane>
  )
}

export default PostsContainer
