import {
  SelectMenu,
  Button,
  Text,
  Pane,
  majorScale,
  TrashIcon,
} from 'evergreen-ui'
import React, { FC, useState } from 'react'
import { Filter } from '../../types'
import styles from './ComboFilter.module.scss'

interface ComboFilterProps {
  title: string
  filters: Filter[]
  placeholder: string
  onSelect: Function
  onDeselect: Function
  selected?: string | string[]
  isMultiSelect: boolean
  style?: React.CSSProperties
}

const ComboFilter: FC<ComboFilterProps> = (
  props: ComboFilterProps,
) => {
  const [filter, setFilter] = useState('')

  const generateComboFilterText = () => {
    if (Array.isArray(props.selected) && props.selected.length > 0) {
      return `${props.placeholder}: ${props.selected?.join(', ')}`
    } else {
      return `${props.placeholder}`
    }
  }

  return (
    <Pane style={props.style}>
      <SelectMenu
        title={props.title}
        onFilterChange={(filter) => setFilter(filter)}
        options={props.filters.map((filter) => ({
          label: filter.value,
          value: filter.value,
        }))}
        selected={props.selected}
        onSelect={(item) => {
          props.onSelect(props.filters.find((f) => f.value === item.value))
        }}
        onDeselect={(item) => {
          props.onDeselect(props.filters.find((f) => f.value === item.value))
        }}
        isMultiSelect={props.isMultiSelect}
      >
        <Button fontSize={'14px'}>{generateComboFilterText()}</Button>
      </SelectMenu>
    </Pane>
  )
}

export default ComboFilter
