import {
  Button,
  Card,
  CaretRightIcon,
  Heading,
  majorScale,
  Pane,
  Spinner,
  Text,
} from 'evergreen-ui'
import { FC, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { CONSTANTS } from '../../../constants'
import {
  useEditNoteMutation,
  useGetNotesQuery,
} from '../../../services/notes/notes.service'
import { EditNoteRequestPayload, Note, User } from '../../../types'
import {
  generateWidgetDetails,
  WidgetDetails,
} from '../../../utils/generate-widget-details'
import { ContentEditorDetails } from '../../ContentEditor/ContentEditor'
import WidgetDetailCard from '../../WidgetDetailCard/WidgetDetailCard'

interface NotesWidgetProps {
  user?: User
  limit?: number
  title?: string
  showHeading?: boolean
  search?: string
}

const NotesWidget: FC<NotesWidgetProps> = (props: NotesWidgetProps) => {
  const [skipCallingApiOnLoad, setSkipCallingApi] = useState(true)
  const [filteredData, setFilteredData] = useState<WidgetDetails[]>([])
  const [widgetData, setWidgetData] = useState<WidgetDetails[]>([])
  const notesLimit = props.limit

  const { isLoading, data } = useGetNotesQuery(
    { userId: props.user?.id, limit: `${notesLimit}` },
    {
      skip: skipCallingApiOnLoad,
      refetchOnMountOrArgChange: true,
    },
  )

  // call the API once we have the User object
  useEffect(() => {
    if (props.user) {
      setSkipCallingApi(false)
    }
  }, [props.user])

  // set data on load
  useEffect(() => {
    if (data) {
      const d = data.map((d) => generateWidgetDetails(d, 'NOTE'))
      setWidgetData(d)
    }
  }, [data])

  // set filtered data
  useEffect(() => {
    setFilteredData(widgetData)
  }, [widgetData])

  // search
  useEffect(() => {
    if (props.search) {
      setFilteredData(
        widgetData.filter(
          (f) =>
            f.content
              .toLowerCase()
              .includes(props.search?.toLowerCase() ?? '') ||
            f.title.toLowerCase().includes(props.search?.toLowerCase() ?? ''),
        ),
      )
    } else {
      setFilteredData(widgetData)
    }
  }, [props.search])

  // edit a note
  const [
    callEditNoteAPI,
    { isLoading: isEditing, isSuccess: isEditSaveSuccess },
  ] = useEditNoteMutation()

  const editNote = async (noteId: string, details: ContentEditorDetails) => {
    const payload: EditNoteRequestPayload = {
      id: noteId,
      title: details.title ?? '',
      note: details.content,
    }

    const savedNote: Note = await callEditNoteAPI(payload).unwrap()

    // update the local note with the latest saved data
    const index = widgetData.findIndex((w) => w.id === savedNote.id)
    if (index > -1) {
      const savedDetail = generateWidgetDetails(savedNote, 'NOTE')
      const updatedArray = [...widgetData]
      updatedArray[index] = savedDetail
      setWidgetData(updatedArray)
    }
  }

  return (
    <Card
      border
      padding={majorScale(2)}
      display="flex"
      flexDirection="column"
      background="white"
      elevation={1}
    >
      {props.showHeading ? (
        <Pane
          display="flex"
          justifyContent="space-between"
          borderBottom="1px dotted #7A8A99;"
          paddingBottom={majorScale(1)}
          alignItems="center"
          marginBottom={majorScale(2)}
        >
          <Heading size={600}>{props.title}</Heading>
          <Button
            is={NavLink}
            to={CONSTANTS.ROUTES.ALL_NOTES}
            appearance="minimal"
            iconAfter={CaretRightIcon}
          >
            View all notes
          </Button>
        </Pane>
      ) : null}
      <Pane>
        {isLoading ? (
          <Spinner size={25} />
        ) : (
          filteredData?.map((d) => (
            <WidgetDetailCard
              details={d}
              key={d.id}
              editable={true}
              isSaving={isEditing}
              isEditSaveSuccess={isEditSaveSuccess}
              edit={(details) => editNote(d.id, details)}
              hasTitle
            ></WidgetDetailCard>
          ))
        )}
        {data?.length === 0 ? (
          <Pane>
            <Text fontSize={'1rem'} display="block">
              No notes yet.
            </Text>
          </Pane>
        ) : null}
      </Pane>
    </Card>
  )
}

export default NotesWidget
