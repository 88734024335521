import { RespondToAuthChallengeCommandOutput, RevokeTokenCommandOutput } from '@aws-sdk/client-cognito-identity-provider'
import { toaster } from 'evergreen-ui'
import { ReactNode, createContext, useContext, useMemo } from 'react'
import { useStore } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CONSTANTS } from '../constants'
import { useAppDispatch } from '../hooks'
import { useGetGroupsForUserMutation, useLoginMutation, useLogoutMutation } from '../services/auth/auth.service'
import { clearAuthState, setAuthResult, setLoginSession, setUser } from '../services/auth/auth.slice'
import { RootState } from '../store'
import { GetGroupsForUserResponse, LoginCredentialsPayload, LoginSuccessResponse } from '../types'

type AuthContextType = {
  auth?: RespondToAuthChallengeCommandOutput
  isLoggingIn: boolean
  isLoggingOut: boolean
  login: (data: LoginCredentialsPayload) => {}
  saveAuthResponse: (data: LoginSuccessResponse) => void
  savePasswordChallengeResponse: (data: LoginSuccessResponse) => void
  getGroupsForUser: () => Promise<GetGroupsForUserResponse[]>
  logout: () => void
}
const AuthContext = createContext<AuthContextType>({
  auth: undefined,
  isLoggingIn: false,
  isLoggingOut: false,
  login: (data: LoginCredentialsPayload) => Promise<void>,
  saveAuthResponse: (data: LoginSuccessResponse) => { },
  savePasswordChallengeResponse: (data: LoginSuccessResponse) => { },
  getGroupsForUser: () => { return Promise.resolve([]) },
  logout: () => { },
})

interface AuthProviderProps {
  children?: ReactNode
}

// Implement the AuthProvider values to be used in the application
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const store = useStore()
  const [callLoginApi, { isLoading: isLoggingIn }] = useLoginMutation()
  const [callLogoutApi, { isLoading: isLoggingOut }] = useLogoutMutation()
  const [callGetGroupsForUserApi, { isLoading: isGettingGroups }] = useGetGroupsForUserMutation()

  // call this function when you want to authenticate the user
  const login = async (data: LoginCredentialsPayload) => {
    try {
      // try login
      const result: LoginSuccessResponse = await callLoginApi(data).unwrap()
      // save the session ID
      dispatch(setLoginSession({
        sessionId: result.Session || '',
        username: data.username
      }))

      if (result.AuthenticationResult?.AccessToken) {
        saveAuthResponse(result)
      }

      // process the result
      const redirectLocation = processLoginResult(result)

      // redirect to the appropriate screen
      navigate(redirectLocation)
    } catch (exception) { }
  }

  // call this function to sign out logged in user
  const logout = async () => {
    try {
      const state = store.getState() as RootState
      const refreshToken = state.auth.authResult?.RefreshToken

      if (refreshToken) {
        const result: RevokeTokenCommandOutput = await callLogoutApi({
          refresh_token: refreshToken
        }).unwrap()
      }

      dispatch(clearAuthState())
    } catch (exception) {
      toaster.danger('Error logging out.')
    }
  }


  const getGroupsForUser = async (): Promise<GetGroupsForUserResponse[]> => {
    return (await callGetGroupsForUserApi(null).unwrap()).Groups
  }

  const saveAuthResponse = (data: LoginSuccessResponse) => {
    dispatch(setAuthResult(data.AuthenticationResult))
    dispatch(setUser(data.user))
  }

  const savePasswordChallengeResponse = (result: LoginSuccessResponse) => {
    saveAuthResponse(result)
  }

  const value: AuthContextType = useMemo(
    () => ({
      isLoggingIn,
      isLoggingOut,
      saveAuthResponse,
      savePasswordChallengeResponse,
      login,
      logout,
      getGroupsForUser,
    }),
    [isLoggingIn, isLoggingOut],
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const processLoginResult = (result: LoginSuccessResponse) => {
  let redirectLocation = ''
  switch (result.ChallengeName) {
    case 'NEW_PASSWORD_REQUIRED':
      redirectLocation = `${CONSTANTS.ROUTES.LOGIN}/${CONSTANTS.ROUTES.NEW_PASSWORD}`
      break
    default:
      redirectLocation = CONSTANTS.ROUTES.DASHBOARD
      break
  }
  return redirectLocation
}

export const useAuth = () => {
  return useContext(AuthContext)
}
