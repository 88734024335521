import { Pane, Textarea, majorScale, Button, TextInput } from 'evergreen-ui'
import React, { ChangeEvent, FC, useState } from 'react'
import TitleTextBox from '../TitleTextBox/TitleTextBox'
import styles from './ContentEditor.module.scss'

export interface ContentEditorDetails {
  title?: string
  content: string
}

interface ContentEditorProps {
  initialValue: ContentEditorDetails
  isSaving: boolean
  edit: (content: ContentEditorDetails) => void
  cancel: () => void
  hasTitle?: boolean
}

const ContentEditor: FC<ContentEditorProps> = (props: ContentEditorProps) => {
  const [editedContent, setEditedContent] = useState<ContentEditorDetails>(
    props.initialValue,
  )

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width="100%"
      alignItems="flex-end"
    >
      {props.hasTitle ? (
        <Pane
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom={majorScale(1)}
        >
          <TitleTextBox
            value={editedContent.title ?? ''}
            setTitle={(value: string) => {
              setEditedContent({ ...editedContent, title: value })
            }}
            fontSize="1rem"
            marginBottom={majorScale(1)}
            display="flex"
            placeholder="Note Title"
            width="100%"
            flex="1"
          ></TitleTextBox>
        </Pane>
      ) : null}
      <Textarea
        boxSizing="border-box"
        width="100%"
        fontSize="1rem"
        whiteSpace="pre-line"
        defaultValue={props.initialValue.content}
        marginBottom={majorScale(2)}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          setEditedContent({ ...editedContent, content: e.target.value })
        }}
      ></Textarea>
      <Pane display="flex">
        <Button
          appearance="secondary"
          marginRight={majorScale(1)}
          onClick={() => {
            props.cancel()
          }}
          disabled={props.isSaving}
        >
          Cancel
        </Button>
        <Button
          appearance="primary"
          onClick={() => {
            props.edit(editedContent)
          }}
          isLoading={props.isSaving}
        >
          Save
        </Button>
      </Pane>
    </Pane>
  )
}

export default ContentEditor
