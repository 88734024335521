import { majorScale, Pane } from 'evergreen-ui'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'
import { CONSTANTS } from '../../constants'
import { generatePageTitle } from '../../utils/generate-page-title'
interface SubscribePageProps {}

const pageStyles = {
  backgroundImage: 'url(/doctor-splash.png)',
  backgroundPosition: 'center',
  backgroundSize: 'cover'
};

const paneStyles = {
  backgroundColor: '#fffc'
};

const SubscribePage: FC<SubscribePageProps> = () => {
  const title = generatePageTitle(CONSTANTS.TITLES.SUBSCRIBE)

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      style={pageStyles}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
        <Pane
          flex="2"
          padding={majorScale(10)}
          display="flex"
          alignContent="center"
          justifyContent="center"
          style={paneStyles}
        >
          <Outlet></Outlet>
        </Pane>
    </Pane>
  )
}
export default SubscribePage
