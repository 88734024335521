import {
  Pane,
  majorScale,
  Heading,
  TextInputField,
  toaster,
  Alert,
  Dialog,
} from 'evergreen-ui'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { CONSTANTS } from '../../constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setUser } from '../../services/auth/auth.slice'
import { useEditUserMutation } from '../../services/users/users.service'
import { User } from '../../types'
import { generatePageTitle } from '../../utils/generate-page-title'

import styles from './OnboardPage.module.scss'

interface OnboardPageProps {}

const OnboardPage: FC<OnboardPageProps> = () => {
  const title = generatePageTitle(CONSTANTS.TITLES.ONBOARDING)
  let [showDialog, setShowDialog] = useState<boolean>(true)
  let [errors, setErrors] = useState<string | undefined>(undefined)
  let [userForm, setUserForm] = useState<User>({
    id: '',
    email: '',
    first_name: '',
    last_name: '',
    prefix: '',
    suffix: '',
    specialities: [],
    is_onboarded: false,
  })

  const dispatch = useAppDispatch()

  const user: User | undefined = useAppSelector((state) => state.auth.user)

  useEffect(() => {
    if (user) {
      setUserForm(user)
    }
  }, [user])

  // edit a user
  const [
    callEditUserApi,
    { isLoading: isUpdatingUser, isSuccess: isEditSaveSuccess },
  ] = useEditUserMutation()

  const validate = () => {
    if (userForm.first_name.length === 0 || userForm.last_name.length === 0) {
      setErrors('Please complete the required fields.')
    } else {
      setErrors(undefined)
      submitForm()
    }
  }

  const submitForm = async () => {
    const payload: User = {
      ...userForm,
      is_onboarded: true,
    }
    try {
      const savedUser: User = await callEditUserApi(payload).unwrap()
      dispatch(setUser(savedUser))

      setShowDialog(false)
      toaster.success('User updated!')
    } catch (exception) {
      toaster.danger('Error updating user.')
    }
  }

  return (
    <Dialog
      isShown={showDialog}
      title="Before we get started..."
      hasClose={false}
      hasCancel={false}
      confirmLabel="Save"
      onConfirm={() => validate()}
      isConfirmLoading={isUpdatingUser}
    >
      <Pane>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Heading size={700} marginBottom={majorScale(2)}>
          Tell us about yourself
        </Heading>
        <Heading size={200} marginBottom={majorScale(2)}></Heading>

        {errors ? (
          <Alert intent="danger" marginBottom={majorScale(2)}>
            {errors}
          </Alert>
        ) : null}

        <TextInputField
          label="Prefix"
          value={userForm.prefix}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUserForm({ ...userForm, prefix: e.target.value })
          }
        />

        <TextInputField
          label="First name"
          required
          value={userForm.first_name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUserForm({ ...userForm, first_name: e.target.value })
          }
        />
        <TextInputField
          label="Last name"
          required
          value={userForm.last_name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUserForm({ ...userForm, last_name: e.target.value })
          }
        />

        <TextInputField
          label="Suffix"
          value={userForm.suffix}
          description="Example: M.D. or Ph. D."
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUserForm({ ...userForm, suffix: e.target.value })
          }
        />
      </Pane>
    </Dialog>
  )
}

export default OnboardPage
