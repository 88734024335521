import { compareAsc, compareDesc, parseISO } from 'date-fns'
import { CONSTANTS } from '../constants'

export const sortHelper = (array: Array<any>, sortBy: string) => {
  if (!array || array.length === 0) {
    return []
  }
  let arr = [...array]
  switch (sortBy) {
    case CONSTANTS.SORT.TYPES.MOST_RECENT:
    case CONSTANTS.SORT.TYPES.NEWEST:
      arr = sortByDateDesc(arr)
      break
    case CONSTANTS.SORT.TYPES.OLDEST:
      arr = sortByDateAsc(arr)
      break
    case CONSTANTS.SORT.TYPES.TRENDING:
      arr = sortByTrendingDesc(arr)
      break
    case CONSTANTS.SORT.TYPES.MOST_RELEVANT:
      arr = sortByRelevance(arr)
      break
    case CONSTANTS.SORT.TYPES.HIGHEST_RATED:
      arr = sortByHighestRated(arr)
      break
    case CONSTANTS.SORT.TYPES.PATIENT_ASCENDING:
      arr = sortByPatientCluster(arr, true)
      break
    case CONSTANTS.SORT.TYPES.PATIENT_DESCENDING:
      arr = sortByPatientCluster(arr, false)
      break
  }
  return arr
}

const sortByDateDesc = (array: Array<any>) => {
  if (!array || array.length === 0) {
    return []
  }
  const arr = [...array]

  arr.sort((a, b) =>
    compareDesc(
      parseISO(a.created_at?.toString()),
      parseISO(b.created_at?.toString()),
    ),
  )

  return arr
}
export const sortByDateAsc = (array: Array<any>) => {
  if (!array || array.length === 0) {
    return []
  }
  const arr = [...array]

  arr.sort((a, b) =>
    compareAsc(
      parseISO(a.created_at?.toString()),
      parseISO(b.created_at?.toString()),
    ),
  )

  return arr
}

export const sortByTrendingDesc = (array: Array<any>) => {
  if (!array || array.length === 0) {
    return []
  }
  const arr = [...array]

  arr.sort((a, b) =>
    compareDesc(
      a.meta?.comments_past_week ?? 0,
      b.meta?.comments_past_week ?? 0,
    ),
  )

  return arr
}

const sortByRelevance = (array: Array<any>) => {
  if (!array || array.length === 0) {
    return []
  }
  const arr = [...array]

  arr.sort((a, b) => {
    const diff = a.relevance_score - b.relevance_score
    if (diff > 0) {
      return -1
    } else if (diff < 0) {
      return 1
    } else {
      return 0
    }
  })

  return arr
}

const sortByHighestRated = (array: Array<any>) => {
  if (!array || array.length === 0) {
    return []
  }
  const arr = [...array]

  arr.sort((a, b) => {
    const diff = a.upvotes - b.upvotes
    if (diff > 0) {
      return -1
    } else if (diff < 0) {
      return 1
    } else {
      return 0
    }
  })

  return arr
}
const sortByPatientCluster = (array: Array<any>, ascending: boolean) => {
  if (!array || array.length === 0) {
    return []
  }
  const arr = [...array]

  if (ascending) {
    arr.sort((a, b) => {
      return (
        (a.cluster || 0) - (b.cluster || 0) || (a.sort || 0) - (b.sort || 0)
      )
    })
  } else {
    arr.sort((a, b) => {
      return (
        (b.cluster || 0) - (a.cluster || 0) || (a.sort || 0) - (b.sort || 0)
      )
    })
  }

  return arr
}
