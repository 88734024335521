import {
  Badge,
  Card,
  Heading,
  InboxIcon,
  Link,
  Pane,
  Pill,
  Text,
  Tooltip,
  TrendingUpIcon,
  majorScale,
  minorScale
} from 'evergreen-ui'
import { FC } from 'react'
import { Post } from '../../types'
import { badgeColorSelector } from '../../utils/badge-color-selector'
import { convertToFriendlyDate } from '../../utils/convert-date'
import { getSnippet } from '../../utils/get-snippet'
import styles from './PostBox.module.scss'

interface PostBoxProps {
  post: Post
  isActive: boolean
  onClick: (id: string) => void
}

let commentsPastWeekPill = (props: PostBoxProps) => (props.post.meta?.comments_past_week ?? 0) > 0 && (
  <>
    <Pill color="green" display="flex" alignItems="center">
        <Tooltip content="Trending: comments this week" position="bottom">
          <Text>
              <TrendingUpIcon marginRight={minorScale(1)} size={9} />
              {props.post.meta?.comments_past_week ?? 0}
          </Text>
        </Tooltip>
    </Pill>
    <Pane marginLeft={minorScale(1)}></Pane>
  </>
);

let commentsPill = (props: PostBoxProps) => (props.post.meta?.comments_count ?? 0) > 0 && (
  <>
    <Pill color="neutral" display="flex" alignItems="center">
        <Tooltip content="Total comments" position="bottom">
          <Text>
              <InboxIcon marginRight={minorScale(1)} size={9} />
              {props.post.meta?.comments_count}
          </Text>
        </Tooltip>
    </Pill>
    <Pane marginLeft={minorScale(1)}></Pane>
  </>
);

const PostBox: FC<PostBoxProps> = (props: PostBoxProps) => {
  return (
    <Link
      type="button"
      onClick={() => props.onClick(props.post.id)}
      cursor="pointer"
      marginBottom={majorScale(2)}
    >
      <Card
        elevation={1}
        display="flex"
        padding={majorScale(2)}
        flexDirection="column"
        className={props.isActive ? styles.ActiveCard : ''}
      >
        
        <Pane display="flex" justifyContent="space-between">
          <Heading size={500}>{props.post.title}</Heading>
          {props.post.user && (
            <Text
              size={400}
              color="gray"
            >
              {props.post.user.first_name} {props.post.user.last_name}
            </Text>
          )}
        </Pane>
        <Text
          color="gray"
          marginBottom={majorScale(2)}
          textTransform="uppercase"
        >
          {convertToFriendlyDate(props.post.created_at)}
        </Text>
        <Pane marginBottom={majorScale(2)} color='#696f8c' fontSize={'0.9rem'}>
          {getSnippet(props.post.content, 100)}
        </Pane>
        <Pane display="flex" justifyContent="space-between">
          <Pane>
            {props.post.tags?.map((tag) => (
              <Badge key={tag.id} color={badgeColorSelector(tag.tag.name)}>
                {tag.tag.name}
              </Badge>
            ))}
          </Pane>
          <Pane display="flex" justifyContent="flex-end">
            {commentsPastWeekPill(props)}
            {commentsPill(props)}
          </Pane>
        </Pane>
      </Card>
    </Link>
  )
}

export default PostBox
