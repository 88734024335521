import { CONSTANTS } from '../constants'
import { convertToFriendlyDate } from './convert-date'
import {
  generateCleanPageTitle,
  generateSubpageTitle,
} from './generate-page-title'

export type WidgetType = 'COMMENT' | 'RATING' | 'NOTE'

export interface WidgetDetails {
  id: string
  title: string
  content: string
  route: string
  type: string
  created_at: string
  button_text: string
  original: any
}
export const generateWidgetDetails = (
  obj: any,
  type: WidgetType,
): WidgetDetails => {
  let result: WidgetDetails = {
    id: obj.id,
    created_at: convertToFriendlyDate(obj.created_at, true) ?? '',
    content: '',
    title: '',
    route: '',
    type: '',
    button_text: '',
    original: obj,
  }

  if (type === 'RATING' && obj.video) {
    result = {
      ...result,
      title: generateSubpageTitle(
        CONSTANTS.TITLES.PATIENT_VIDEOS,
        obj.video.name,
      ),
      route: `${CONSTANTS.ROUTES.PATIENT_VIDEOS}/${obj.video_id}`,
      type: CONSTANTS.WIDGET_DETAILS.TYPES.VIDEO,
      button_text: 'Video',
    }
  } else if (type === 'RATING' && obj.post) {
    result = {
      ...result,
      title: generateSubpageTitle(CONSTANTS.TITLES.COMMUNITY, obj.post.title),
      route: `${CONSTANTS.ROUTES.COMMUNITY}/${obj.post_id}`,
      type: CONSTANTS.WIDGET_DETAILS.TYPES.POST,
      button_text: 'Post',
    }
  } else if (type === 'RATING' && obj.comment && obj.comment.post_id) {
    result = {
      ...result,
      title: 'Post Comment',
      route: `${CONSTANTS.ROUTES.COMMUNITY}/${obj.comment.post_id}#${obj.comment.id}`,
      content: obj.comment.comment,
      type: CONSTANTS.WIDGET_DETAILS.TYPES.POST_COMMENT,
      button_text: generateSubpageTitle(
        CONSTANTS.TITLES.COMMUNITY,
        obj.comment.post.title,
      ),
    }
  } else if (type === 'RATING' && obj.comment && obj.comment.video_id) {
    result = {
      ...result,
      title: 'Video Comment',
      route: `${CONSTANTS.ROUTES.PATIENT_VIDEOS}/${obj.comment.video_id}#${obj.comment.id}`,
      content: obj.comment.comment,
      type: CONSTANTS.WIDGET_DETAILS.TYPES.VIDEO_COMMENT,
      button_text: generateSubpageTitle(
        CONSTANTS.TITLES.PATIENT_VIDEOS,
        obj.comment.video.name,
      ),
    }
  } else if (type === 'COMMENT' && obj.video_id) {
    result = {
      ...result,
      title: 'Comment',
      route: `${CONSTANTS.ROUTES.PATIENT_VIDEOS}/${obj.video_id}#${obj.id}`,
      content: obj.comment,
      type: CONSTANTS.WIDGET_DETAILS.TYPES.COMMENT,
      button_text: generateSubpageTitle(
        CONSTANTS.TITLES.PATIENT_VIDEOS,
        obj.video?.name,
      ),
    }
  } else if (type === 'COMMENT' && obj.post_id) {
    result = {
      ...result,
      title: 'Comment',
      route: `${CONSTANTS.ROUTES.COMMUNITY}/${obj.post_id}#${obj.id}`,
      content: obj.comment,
      type: CONSTANTS.WIDGET_DETAILS.TYPES.COMMENT,
      button_text: generateSubpageTitle(
        CONSTANTS.TITLES.COMMUNITY,
        obj.post?.title,
      ),
    }
  } else if (type === 'NOTE') {
    result = {
      ...result,
      title: obj.title,
      route: obj.route,
      content: obj.note,
      button_text: generateCleanPageTitle(obj.route_title),
    }
  }
  return result
}
