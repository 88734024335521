import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CONSTANTS } from '../../../constants'
import { UserComment } from '../../../types'
import { sortHelper } from '../../../utils/sort'

// Define a type for the slice state
interface PostDetailState {}

// Define the initial state using that type
const initialState: PostDetailState = {}

export const postDetailPageSlice = createSlice({
  name: 'postDetailPageSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
})

export const {} = postDetailPageSlice.actions

export default postDetailPageSlice.reducer
