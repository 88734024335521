import { QueryStatus } from '@reduxjs/toolkit/dist/query'
import {
  Pane,
  Heading,
  majorScale,
  Alert,
  TextInputField,
  Button,
  Spinner,
  Text,
  ChevronLeftIcon,
} from 'evergreen-ui'
import { ChangeEvent, FC, FormEvent, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { CONSTANTS } from '../../constants'
import { useRequestForgotPasswordLinkMutation } from '../../services/auth/auth.service'
import { ForgotPasswordResetLinkRequestPayload } from '../../types'
import { generatePageTitle } from '../../utils/generate-page-title'

interface ForgotPasswordProps {}

const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const title = generatePageTitle(CONSTANTS.TITLES.FORGOT_PASSWORD)

  const [formValue, setFormValue] = useState<
    ForgotPasswordResetLinkRequestPayload
  >({
    username: '',
  })

  const navigate = useNavigate()

  const [
    callResetPasswordApi,
    { isLoading, status },
  ] = useRequestForgotPasswordLinkMutation()

  const submitUsername = async (e?: FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault()
    }

    try {
      const postData: ForgotPasswordResetLinkRequestPayload = { ...formValue }

      // try update
      const result = await callResetPasswordApi(postData).unwrap()
    } catch (exception) {}
  }

  // Show the completed message
  const showSuccessMessage = () => {
    return status === QueryStatus.fulfilled ? (
      <Alert
        intent="success"
        title="Check your email."
        marginBottom={majorScale(2)}
      >
        If you have an account with us, you will receive a password reset link
        in your inbox shortly.
      </Alert>
    ) : null
  }

  // Navigate back to login
  const goBack = () => {
    navigate(CONSTANTS.ROUTES.LOGIN)
  }

  return (
    <Pane width="70%">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Button
        appearance="secondary"
        onClick={() => goBack()}
        marginBottom={majorScale(3)}
      >
        <ChevronLeftIcon marginRight={majorScale(1)}></ChevronLeftIcon>Back
      </Button>
      <Heading size={800} marginBottom={majorScale(4)}>
        No worries!
      </Heading>
      {showSuccessMessage()}
      <Heading size={900}>Reset password</Heading>
      <Heading size={800} color="#696F8C" marginBottom={majorScale(4)}>
        Please enter your account's email address.
      </Heading>
      <form onSubmit={(e) => submitUsername(e)}>
        <TextInputField
          id="email"
          label="Email"
          required
          placeholder="Email"
          type={'email'}
          name="password"
          value={formValue.username}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValue({ ...formValue, username: e.target.value })
          }
        ></TextInputField>

        <Button
          onClick={() => {
            submitUsername()
          }}
          marginBottom={majorScale(4)}
          fontSize="14px"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner size={majorScale(2)}></Spinner>
          ) : (
            <Text>Submit</Text>
          )}
        </Button>
      </form>
    </Pane>
  )
}

export default ForgotPassword
