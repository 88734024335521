import {
  Button,
  Card,
  Heading,
  Pane,
  Spinner,
  majorScale
} from 'evergreen-ui'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import ComboFilter from '../../components/ComboFilter/ComboFilter'
import Sort from '../../components/Sort/Sort'
import VideoCard from '../../components/VideoCard/VideoCard'
import { CONSTANTS } from '../../constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useAuth } from '../../hooks/auth.hook'
import { useGetTaxonomiesQuery } from '../../services/taxonomy/taxonomy.service'
import { useGetVideosByCategoryQuery } from '../../services/videos/videos.service'
import { Filter, FilterOption, GetGroupsForUserResponse, Video } from '../../types'
import { generatePageTitle } from '../../utils/generate-page-title'
import {
  clearFilters,
  setAllVideos,
  setFilteredVideos,
  setFilters,
  sortVideos,
  toggleFilter,
} from './PatientVideos.slice'
import styles from './PatientVideosPage.module.scss'

interface PatientVideosPageProps {}

const PatientVideosPage: FC<PatientVideosPageProps> = (
  props: PatientVideosPageProps,
) => {
  const { getGroupsForUser } = useAuth()
  const title = generatePageTitle(CONSTANTS.TITLES.PATIENT_VIDEOS)

  const [isAuthorized, setIsAuthorized] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  let navigate = useNavigate()
  const dispatch = useAppDispatch()

  // get ALL AVAILABLE FILTERS from store
  const filters: FilterOption[] = useAppSelector(
    (state) => state.patientVideos.allFilters,
  )

  // get ACTIVE FILTERS from store
  const activeFilters = useAppSelector(
    (state) => state.patientVideos.activeFilters,
  )
  // get FILTERED VIDEOS from store
  const filteredVideos: Video[] = useAppSelector(
    (state) => state.patientVideos.filteredVideos,
  )

  // get ACTIVE SORT from store
  const activeSort: string = useAppSelector(
    (state) => state.patientVideos.activeSort,
  )

  // API call to get the video data
  const { data } = useGetVideosByCategoryQuery('', {
    refetchOnMountOrArgChange: true,
  })

  const {
    data: taxonomyData,
    isLoading: isLoadingTaxonomy,
  } = useGetTaxonomiesQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  // when data is retrieved from the API, update the state
  useEffect(() => {
    if (data) {
      dispatch(setAllVideos(data))
      dispatch(setFilteredVideos())
      dispatch(sortVideos(activeSort))
    }
  }, [data])

  // when the user logs in, check their auth groups
  useEffect(() => {
    setIsLoading(true)
    if (getGroupsForUser) {
      getGroupsForUser()
        .then((res: GetGroupsForUserResponse[]) => {
          if (res) {
            setIsAuthorized(
              res.some((group) => group.GroupName === CONSTANTS.GROUPS.VIDEOS)
            )
            setIsLoading(false)
          }
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    }
  }, [getGroupsForUser])

  // update the videos when the filter is set
  useEffect(() => {
    dispatch(setFilteredVideos(activeFilters))
    dispatch(sortVideos(activeSort))
  }, [activeFilters])

  useEffect(() => {
    dispatch(setFilters(taxonomyData))
  }, [taxonomyData])

  const filterVideos = (filter: Filter) => {
    dispatch(toggleFilter(filter))
  }

  const goToVideo = (id: string) => {
    navigate(`${CONSTANTS.ROUTES.PATIENT_VIDEOS}/${id}`)
  }

  const doSort = (sortBy: string) => {
    dispatch(sortVideos(sortBy))
  }

  const clearAllFilters = () => {
    dispatch(clearFilters())
  }

  return (
    <Pane
      flex={4}
      className={styles.PatientVideosPage}
      data-testid="PatientVideosPage"
      padding={majorScale(4)}
      background="tint2"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Heading size={900} marginBottom={majorScale(1)}>
        Therapeutic Needling Videos
      </Heading>
      {isLoading ? (
        <Heading>Loading...</Heading>
      ) : isAuthorized ? (
        <>
          <Card
            border="default"
            padding={majorScale(2)}
            display="flex"
            background="white"
            alignItems="center"
            marginTop={majorScale(4)}
            borderRadius={10}
            justifyContent="space-between"
          >
            <Pane
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              gap={majorScale(2)}
            >
              <Heading>Filters:</Heading>
              <Pane
                display="flex"
                gap={majorScale(1)}
                flexWrap="wrap"
                alignItems="center"
              >
                {isLoadingTaxonomy ? (
                  <Spinner />
                ) : (
                  filters.map((filterOption) => (
                    <ComboFilter
                      key={filterOption.category}
                      title={filterOption.category}
                      placeholder={filterOption.category}
                      filters={filterOption.filters}
                      selected={activeFilters
                        .filter((af) => af.category === filterOption.category)
                        .flatMap((f) => f.value)}
                      onSelect={(filter: Filter) => filterVideos(filter)}
                      onDeselect={(filter: Filter) => filterVideos(filter)}
                      isMultiSelect={true}
                      style={{ marginRight: majorScale(1) }}
                    ></ComboFilter>
                  ))
                )}
              </Pane>
            </Pane>
            <Pane>
              <Button appearance="minimal" onClick={() => clearAllFilters()}>
                Clear Filters
              </Button>
            </Pane>
          </Card>
          <Pane marginY={majorScale(2)} className="col-md-12 col-lg-4 col-xl-3">
            <Sort
              options={CONSTANTS.SORT.PATIENT_VIDEOS.OPTIONS}
              selected={activeSort}
              sort={(sortBy: string) => doSort(sortBy)}
            ></Sort>
          </Pane>
          <Pane marginTop={majorScale(2)} display="flex" flexWrap="wrap">
            {filteredVideos.map((vid) => (
              <Pane
                key={vid.id}
                className="col-12 col-md-6 col-lg-4 col-xl-3"
                padding={majorScale(2)}
              >
                <VideoCard
                  key={vid.id}
                  data={vid}
                  navigate={() => goToVideo(vid.id)}
                ></VideoCard>
              </Pane>
            ))}
          </Pane>
        </>
      ) : (
        <Heading>You are not authorized to view this page.</Heading>
      )}
    </Pane>
  )
}

export default PatientVideosPage
