import {
  Button,
  Card,
  CaretRightIcon,
  Heading,
  majorScale,
  Pane,
  Spinner,
  Text
} from 'evergreen-ui'
import { FC, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { CONSTANTS } from '../../../constants'
import { useGetCommentsQuery } from '../../../services/comments/comments.service'
import { User } from '../../../types'
import {
  generateWidgetDetails,
  WidgetDetails,
} from '../../../utils/generate-widget-details'
import WidgetDetailCard from '../../WidgetDetailCard/WidgetDetailCard'

interface CommentsWidgetProps {
  user?: User
  limit?: number
  title?: string
  search?: string
  showHeading?: boolean
}

const CommentsWidget: FC<CommentsWidgetProps> = (
  props: CommentsWidgetProps,
) => {
  const [skipCallingApiOnLoad, setSkipCallingApi] = useState(true)
  const [filteredData, setFilteredData] = useState<WidgetDetails[]>([])
  const [widgetData, setWidgetData] = useState<WidgetDetails[]>([])
  const commentsLimit = props.limit

  const { isLoading, data } = useGetCommentsQuery(
    { user_id: props.user?.id, limit: `${commentsLimit}` },
    {
      skip: skipCallingApiOnLoad,
      refetchOnMountOrArgChange: true,
    },
  )

  // call the API once we have the User object
  useEffect(() => {
    if (props.user) {
      setSkipCallingApi(false)
    }
  }, [props.user])

  // generate widget details on data load
  useEffect(() => {
    if (data) {
      const widgetData = data.map((d) => generateWidgetDetails(d, 'COMMENT'))
      setWidgetData(widgetData)
      setFilteredData(widgetData)
    }
  }, [data])

  useEffect(() => {
    if (props.search) {
      setFilteredData(
        widgetData.filter(
          (f) =>
            f.content
              .toLowerCase()
              .includes(props.search?.toLowerCase() ?? '') ||
            f.title.toLowerCase().includes(props.search?.toLowerCase() ?? ''),
        ),
      )
    } else {
      setFilteredData(widgetData)
    }
  }, [props.search])

  return (
    <Card
      border
      padding={majorScale(2)}
      display="flex"
      flexDirection="column"
      background="white"
      elevation={1}
    >
      {props.showHeading ? (
        <Pane
          display="flex"
          justifyContent="space-between"
          borderBottom="1px dotted #7A8A99;"
          paddingBottom={majorScale(1)}
          alignItems="center"
          marginBottom={majorScale(2)}
        >
          <Heading size={600}>{props.title}</Heading>
          <Button
            is={NavLink}
            to={CONSTANTS.ROUTES.ALL_COMMENTS}
            appearance="minimal"
            iconAfter={CaretRightIcon}
          >
            View all comments
          </Button>
        </Pane>
      ) : null}

      <Pane>
        {isLoading ? (
          <Spinner size={25} />
        ) : (
          filteredData?.map((d) => (
            <WidgetDetailCard details={d} key={d.id}></WidgetDetailCard>
          ))
        )}
        {data?.length === 0 ? (
          <Pane>
            <Text fontSize={'1rem'} display="block">
              No comments yet.
            </Text>
          </Pane>
        ) : null}
      </Pane>
    </Card>
  )
}

export default CommentsWidget
