import {
  Button,
  Card,
  ChevronLeftIcon,
  Heading,
  Pane,
  Spinner,
  majorScale
} from 'evergreen-ui'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import CommentsContainer from '../../components/CommentsContainer/CommentsContainer'
import VideoPlayerContainer from '../../components/VideoPlayerContainer/VideoPlayerContainer'
import { CONSTANTS } from '../../constants'
import { useAppSelector } from '../../hooks'
import { useGetVideoByIdQuery } from '../../services/videos/videos.service'
import { User } from '../../types'
import {
  generatePageTitle,
  generateSubpageTitle
} from '../../utils/generate-page-title'
import styles from './PatientVideosDetailPage.module.scss'

interface PatientVideosDetailPageProps {}

const PatientVideosDetailPage: FC<PatientVideosDetailPageProps> = () => {
  const [title, setTitle] = useState<string>(
    generatePageTitle(CONSTANTS.TITLES.PATIENT_VIDEOS),
  )
  let params = useParams()
  let navigate = useNavigate()

  const [skipCallingApiOnLoad, setSkipCallingApi] = useState(true)
  const [videoId, setVideoId] = useState<string>('')

  // get values from states
  const user: User | undefined = useAppSelector((state) => state.auth.user)

  // Get video data
  const { data: videoData } = useGetVideoByIdQuery(videoId, {
    skip: skipCallingApiOnLoad,
  })

  // listen to the route to make sure we get
  // the route param required to make API call
  useEffect(() => {
    if (params.videoId) {
      setVideoId(params.videoId)
    }
  }, [params.videoId])

  // enable the API once we have the video ID
  useEffect(() => {
    if (videoId !== '') {
      setSkipCallingApi(false)
    }
  }, [videoId])

  // set the page title to the video name
  useEffect(() => {
    if (videoData) {
      setTitle(
        generatePageTitle(
          generateSubpageTitle(CONSTANTS.TITLES.PATIENT_VIDEOS, videoData.name),
        ),
      )
    }
  }, [videoData])

  // Navigate back to patient videos page
  const goBack = () => {
    navigate(CONSTANTS.ROUTES.PATIENT_VIDEOS)
  }

  // show spinner when loading video
  const showVideo = () => {
    if (!videoData || !user) {
      return <Spinner></Spinner>
    }

    return (
      <Pane>
        <VideoPlayerContainer
          video={videoData}
          user={user}
        ></VideoPlayerContainer>
      </Pane>
    )
  }

  return (
    <Pane
      flex={4}
      className={styles.PatientVideosDetailPage}
      data-testid="PatientVideosPage"
      padding={majorScale(4)}
      background="tint2"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Pane marginBottom={majorScale(2)}>
        <Heading size={700} className="crumb parent" onClick={() => goBack()}>
        Therapeutic Needling Videos
        </Heading>
        <Heading size={900} className="crumb child">
          {videoData?.name}
        </Heading>
      </Pane>
      <Button
        appearance="secondary"
        onClick={() => goBack()}
        marginBottom={majorScale(3)}
      >
        <ChevronLeftIcon marginRight={majorScale(1)}></ChevronLeftIcon>Back
      </Button>
      <Card
        border="default"
        padding={majorScale(2)}
        display="flex"
        flexDirection="column"
        background="white"
        borderRadius={10}
      >
        {showVideo()}
      </Card>
      <CommentsContainer
        sortOptions={CONSTANTS.SORT.PATIENT_VIDEOS_DETAIL.OPTIONS}
        defaultSort={CONSTANTS.SORT.PATIENT_VIDEOS.DEFAULT}
        contextId={videoId}
        contextAuthorId={''}
        contextType="VIDEO"
      />
    </Pane>
  )
}

export default PatientVideosDetailPage
