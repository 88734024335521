export const CONSTANTS = {
  API: {
    INITIALIZE: '/initialize',
    VIDEOS: '/videos',
    COMMENTS: '/comments',
    RATINGS: '/ratings',
    SURVEYS: '/surveys',
    SUBSCRIBE: '/subscribe',
    POSTS: '/posts',
    TAGS: '/tags',
    TAXONOMIES: '/taxonomies',
    NOTES: '/notes',
    UPLOADS: '/uploads',
    USERS: '/users',
    LOGIN: '/login',
    LOGOUT: '/logout',
    REFRESH_TOKEN: '/refresh-token',
    PASSWORD_CHALLENGE: '/password-challenge',
    FORGOT_PASSWORD: '/forgot-password',
    UPDATE_PASSWRD: '/change-password',
    MY_GROUPS: '/mygroups',
  },
  STORAGE_KEYS: {
    SIGNED_URL: 'SIGNED_URL',
  },
  FILTERS: {
    ALL: 'ALL',
  },
  ROUTES: {
    DASHBOARD: '/dashboard',
    ALL_COMMENTS: '/dashboard/comments',
    ALL_NOTES: '/dashboard/notes',
    ALL_LIKES: '/dashboard/likes',
    PATIENT_VIDEOS: '/patient-videos',
    COURSES: '/courses',
    COMMUNITY: '/community',
    WRITE_POST: '/create',
    ACCOUNT: '/account',
    LOGIN: '/login',
    SUBSCRIBE: '/subscribe',
    LOGIN_MAIN: 'main',
    NEW_PASSWORD: 'new-password',
    FORGOT_PASSWORD: 'forgot-password',
    ONBOARDING: '/onboarding',
  },
  COMMENT_VISIBILITY: {
    PUBLIC: 'PUBLIC',
    PRIVATE: 'PRIVATE',
  },
  SORT: {
    TYPES: {
      MOST_RECENT: 'Most Recent',
      MOST_RELEVANT: 'Most Relevant',
      NEWEST: 'Newest',
      OLDEST: 'Oldest',
      HIGHEST_RATED: 'Highest Rated',
      PATIENT_ASCENDING: 'Patient Ascending',
      PATIENT_DESCENDING: 'Patient Descending',
      TRENDING: 'Trending',
    },
    PATIENT_VIDEOS: {
      OPTIONS: ['Patient Ascending', 'Patient Descending','Newest', 'Oldest', 'Trending'],
      DEFAULT: 'Patient Ascending',
    },
    PATIENT_VIDEOS_DETAIL: {
      OPTIONS: ['Newest', 'Oldest'],
      DEFAULT: 'Newest',
    },
    COMMUNITY: {
      OPTIONS: ['Newest', 'Oldest', 'Trending'],
      DEFAULT: 'Newest',
    },
    POST_DETAIL: {
      OPTIONS: ['Newest', 'Oldest'],
      DEFAULT: 'Newest',
    },
  },
  POST_TYPES: {
    OPTIONS: [
      'Case Question',
      'Case Presentation',
      'General Comment',
      'Journal Club',
      'In the News',
    ],
    TYPE: {
      GENERAL: 'General',
      HELP: 'I need help!',
      ASK: 'Ask your colleagues',
      TEACH: 'Teach your colleagues',
      JOURNAL_CLUB: 'Journal Club',
      LAY_MEDIA: 'Lay media',
      PRACTICE_MANAGEMENT: 'Practice management',
    },
  },
  LIMITS: {
    MAX_TITLE_LENGTH: 50,
  },
  CONTEXT_TYPES: {
    COMMUNITY_POST: 'COMMUNITY_POST',
    VIDEO: 'VIDEO',
  },
  RATINGS: {
    VALUES: {
      UPVOTE: 1,
      DOWNVOTE: -1,
    },
    TYPES: {
      HELPFUL: 'HELPFUL',
      NOT_HELPFUL: 'NOT_HELPFUL',
      OTHER: 'OTHER',
    },
  },
  TITLES: {
    BASE: 'Subscriber Portal',
    HOME: 'Home',
    DASHBOARD: 'Dashboard',
    PATIENT_VIDEOS: 'Therapeutic Needling Videos',
    COURSES: 'Courses',
    COMMUNITY: 'Community',
    WRITE_POST: 'Create Post',
    ACCOUNT: 'Account',
    LOGIN: 'Login',
    SUBSCRIBE: 'Subscribe',
    NEW_PASSWORD: 'Reset Password',
    FORGOT_PASSWORD: 'Forgot Password',
    ONBOARDING: 'Onboarding',
  },
  EMAILS: {
    SUPPORT: 'support@mskexperts.com',
  },
  WIDGET_DETAILS: {
    TYPES: {
      VIDEO: 'Video',
      POST: 'Post',
      COMMENT: 'Comment',
      POST_COMMENT: 'Post Comment',
      VIDEO_COMMENT: 'Video Comment',
    },
  },
  GROUPS: {
    VIDEOS: 'VideoAccess',
    FORUM: 'ForumAccess',
  },
  REDIRECTS: {
    SUBSCRIBE: 'https://register.mskexperts.com/therapeutic-needling/',
    VIDEO_PREVIEW: 'https://register.mskexperts.com/trytni',
  },
  JODIT: {
    ALLOWED_TAGS: "p,a[href],b,h1,h2,h3,h4,h5,i,div,span,strong,em,ul,ol,li"
  }
}
