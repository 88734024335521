import {
  Badge,
  Button,
  Card,
  ChevronLeftIcon,
  Heading,
  Image,
  Pane,
  Pill,
  Text,
  majorScale,
} from 'evergreen-ui'
import Parser from 'html-react-parser'
import { FC, useEffect, useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import { CONSTANTS } from '../../constants'
import { Post, User } from '../../types'
import { badgeColorSelector } from '../../utils/badge-color-selector'
import { convertToFriendlyDate } from '../../utils/convert-date'
import { generateUsername } from '../../utils/username'
import CommentsContainer from '../CommentsContainer/CommentsContainer'
import {
  ContentEditorDetails,
} from '../ContentEditor/ContentEditor'
import EditableContextMenu from '../EditableContextMenu/EditableContextMenu'
import RatingWithSurvey from '../RatingWithSurvey/RatingWithSurvey'

import { useNavigate } from 'react-router-dom'
import WysiwygEditor from '../WysiwygEditor/WysiwygEditor'
import styles from './PostDetailCard.module.scss'

interface PostDetailCardProps {
  post: Post
  user?: User
  isEditSaveSuccess: boolean
  editPost: (content: ContentEditorDetails) => void
  isSavingEdit: boolean
}

const PostDetailCard: FC<PostDetailCardProps> = (
  props: PostDetailCardProps,
) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [lightboxIndex, setLightboxIndex] = useState<number>(-1)
  let navigate = useNavigate()

  useEffect(() => {
    if (props.isEditSaveSuccess) {
      setIsEditing(false)
    }
  }, [props.isEditSaveSuccess])

  // Navigate back to patient videos page
  const goBack = () => {
    navigate(CONSTANTS.ROUTES.COMMUNITY);
  }

  return (
    <Pane>
      <Pane marginBottom={majorScale(2)}>
        <Heading size={700} className="crumb parent" onClick={() => goBack()}>
          Community
        </Heading>
        <Heading size={900} className="crumb child">
          {props.post?.title}
        </Heading>
      </Pane>
      <Button
        appearance="secondary"
        onClick={() => goBack()}
        marginBottom={majorScale(3)}
      >
        <ChevronLeftIcon marginRight={majorScale(1)}></ChevronLeftIcon>Back to Community
      </Button>
      <Card
        border="default"
        background="white"
        borderRadius={10}
        padding={majorScale(2)}
      >
        <Pane
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Pane>
            <Pane marginBottom={majorScale(4)}>
              {props.post.tags?.map((tag) => (
                <Badge key={tag.id} color={badgeColorSelector(tag.tag.name)}>
                  {tag.tag.name}
                </Badge>
              ))}
            </Pane>
          </Pane>
          {/* Only show the edit menu if it is the logged in user */}
          {props.user?.id === props.post.user_id ? (
            <EditableContextMenu
              edit={() => {
                setIsEditing(!isEditing)
              }}
            ></EditableContextMenu>
          ) : null}
        </Pane>

        <Pane>
          {isEditing ? (
            <WysiwygEditor
              initialValue={props.post.content}
              onUpdateContent={(newContent: string) => {
                props.editPost({content: newContent})
                setIsEditing(false)
              }}
            />
          ) : (
            <Text
              display="block"
              marginBottom={majorScale(2)}
              fontSize={'1rem'}
              whiteSpace="pre-line"
              lineHeight={1.5}
            >
              {Parser(props.post.content)}
            </Text>
          )}
        </Pane>

        {(props.post.userUploads?.length ?? 0) > 0 ? (
          <Pane
            display="flex"
            flexDirection="row"
            gap={majorScale(2)}
            marginBottom={majorScale(2)}
          >
            {props.post.userUploads?.map((upload, index) => (
              <Pane
                elevation={1}
                key={upload.id}
                hoverElevation={2}
                cursor={'pointer'}
                onClick={() => setLightboxIndex(index)}
              >
                <Image
                  className={styles.Thumbnail}
                  src={upload.url}
                  width={100}
                  height={100}
                ></Image>
              </Pane>
            ))}
          </Pane>
        ) : null}

        <Pane>
          <RatingWithSurvey
            route={CONSTANTS.ROUTES.COMMUNITY}
            upvotes={props.post.meta?.upvotes}
            postId={props.post.id}
            userId={props.user?.id ?? ''}
            ratings={props.post.ratings}
            displayText="This post provides information that will improve my practice: "
          ></RatingWithSurvey>
        </Pane>
      </Card>
      <Pane
        display="flex"
        alignItems="center"
        width="100%"
        flexDirection="row"
        marginY={majorScale(2)}
        paddingX={majorScale(2)}
      >
        <Text
          color="#696f8c"
          textTransform="uppercase"
          display="inline-flex"
          alignItems="center"
        >
          {generateUsername(props.post.user)}
          <Pill color="neutral">Author</Pill>&nbsp;&nbsp;•&nbsp;&nbsp;
          {convertToFriendlyDate(props.post.created_at)}
        </Text>
      </Pane>
      <CommentsContainer
        contextId={props.post.id}
        defaultSort={CONSTANTS.SORT.POST_DETAIL.DEFAULT}
        sortOptions={CONSTANTS.SORT.POST_DETAIL.OPTIONS}
        contextType="COMMUNITY_POST"
        contextAuthorId={props.post.user_id}
      />
      <Lightbox
        open={lightboxIndex > -1}
        index={lightboxIndex}
        close={() => setLightboxIndex(-1)}
        slides={props.post.userUploads?.map((u) => {
          return { src: u.url }
        })}
      ></Lightbox>
    </Pane>
  )
}

export default PostDetailCard
