import { CONSTANTS } from '../../constants'

export type QuickSurveyOption = {
  code: string
  displayText: string
}

export const SURVEY_OPTIONS = [
  {
    type: CONSTANTS.RATINGS.TYPES.HELPFUL,
    heading: 'How will this help your practice (select all that apply)?',
    options: [
      {
        code: 'PROCEDURAL_TECHNIQUE',
        displayText: 'Procedural Technique',
      },
      {
        code: 'CLINICAL_KNOWLEDGE',
        displayText: 'Clinical Knowledge',
      },
      {
        code: 'PATIENT_EDUCATION',
        displayText: 'Patient Education',
      },
      {
        code: 'PHYSICAL_EXAM',
        displayText: 'Physical Exam / Assessment',
      },
      {
        code: 'EXPANDING_DIFFERENTIAL_DIAGNOSIS',
        displayText: 'Expanding Differential Diagnosis',
      },
      {
        code: 'OTHER',
        displayText: 'Other',
      },
    ],
  },
  {
    type: CONSTANTS.RATINGS.TYPES.NOT_HELPFUL,
    heading: 'Why is this content unhelpful (select all that apply)?',
    options: [
      {
        code: 'HIGHLY_COMPETENT',
        displayText:
          'I am highly competent in all aspects of the content',
      },
      {
        code: 'ADEQUATELY_COMPETENT',
        displayText:
          'I am adequately competent in all aspects of the content',
      },
      {
        code: 'NOT_RELEVANT',
        displayText: 'Not relevant to my practice',
      },
      {
        code: 'OTHER',
        displayText: 'Other',
      },
    ],
  },
]
