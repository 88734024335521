import {
  IconComponent,
  LightbulbIcon,
  SocialMediaIcon,
  TimelineAreaChartIcon,
  LearningIcon,
} from 'evergreen-ui'
import { CONSTANTS } from '../../constants'

export interface AppTab {
  name: string
  route: string
  icon: IconComponent
  group: string | undefined
  externalUrl?: string
  unauthorizedUrl?: string
}

export const AppNavLinks: AppTab[] = [
  {
    name: 'Community',
    route: 'community',
    icon: SocialMediaIcon,
    group: CONSTANTS.GROUPS.FORUM,
    unauthorizedUrl: CONSTANTS.REDIRECTS.SUBSCRIBE,
  },
  {
    name: 'Dashboard',
    route: 'dashboard',
    icon: TimelineAreaChartIcon,
    group: undefined,
  },
  // {
  //   name: 'Course',
  //   route: 'courses',
  //   icon: LearningIcon,
  // },
  {
    name: 'Therapeutic Needling Videos',
    route: 'patient-videos',
    icon: LightbulbIcon,
    group: CONSTANTS.GROUPS.VIDEOS,
    unauthorizedUrl: CONSTANTS.REDIRECTS.VIDEO_PREVIEW,
  },
  {
    name: 'How To Use This Site',
    route: 'external',
    icon: LearningIcon,
    group: undefined,
    externalUrl: 'https://register.mskexperts.com/learn',
  },  
]
