import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Tag } from '../../types'

// Define a type for the slice state
interface CreatePostPageState {
  title: string
  content: string
  tags: Tag[]
}

// Define the initial state using that type
const initialState: CreatePostPageState = {
  title: '',
  content: '',
  tags: [],
}

export const createPostPageSlice = createSlice({
  name: 'createPostPageSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPostTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload ?? ''
    },
    setPostContent: (state, action: PayloadAction<string>) => {
      state.content = action.payload ?? ''
    },
    setPostTags: (state, action: PayloadAction<Tag[]>) => {
      state.tags = action.payload ?? []
    },
    clearCreatePostState: (state, action: PayloadAction<undefined>) => {
      state.title = ''
      state.content = ''
      state.tags = []
    },
  },
})

export const {
  setPostTitle,
  setPostContent,
  setPostTags,
  clearCreatePostState,
} = createPostPageSlice.actions

export default createPostPageSlice.reducer
