import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider'
import { Overlay, Pane } from 'evergreen-ui'
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import './App.scss'
import QuickNote from './components/QuickNote/QuickNote'
import SideNav from './components/SideNav/SideNav'
import TopNav from './components/TopNav/TopNav'
import { CONSTANTS } from './constants'
import { useAppSelector } from './hooks'
import { useAuth } from './hooks/auth.hook'
import OnboardPage from './routes/OnboardPage/OnboardPage'
import { useInitializeQuery } from './services/initialize/initialize.service'
import { User } from './types'

const authExcludedRoutes = [
  CONSTANTS.ROUTES.LOGIN,
  CONSTANTS.ROUTES.SUBSCRIBE,
]

function App() {
  let navigate = useNavigate()
  const location = useLocation()
  const { isLoggingOut } = useAuth()
  const [skipInitializeOnLoad, setSkipInitializeOnLoad] = useState(true)

  const auth: AuthenticationResultType | undefined = useAppSelector(
    (state) => state.auth.authResult,
  )
  const user: User | undefined = useAppSelector((state) => state.auth.user)

  // initialize app with defaults
  useInitializeQuery(null, {
    skip: skipInitializeOnLoad,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    if (!auth && !authExcludedRoutes.includes(location.pathname)) {
      navigate(CONSTANTS.ROUTES.LOGIN)
    } else {
      setSkipInitializeOnLoad(false)

      // redirect to dashboard
      if (!location.pathname || location.pathname === '/') {
        navigate(CONSTANTS.ROUTES.DASHBOARD, { replace: true })
      }
    }
  }, [auth, location.pathname, navigate])

  return (
    <Pane display="flex" flexDirection="column" height="100%">
      <Overlay
        isShown={isLoggingOut}
        shouldCloseOnClick={false}
        shouldCloseOnEscapePress={false}
        children={(close: Function, state: string) => {}}
      />
      <TopNav></TopNav>
      <Pane display="flex" flex="auto" background="tint2">
        <SideNav />
        <Outlet />
      </Pane>
      <QuickNote show={auth !== undefined}></QuickNote>
      {!user?.is_onboarded ? <OnboardPage></OnboardPage> : null}
    </Pane>
  )
}

export default App
