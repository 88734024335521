import { FC } from 'react'
import { Outlet } from 'react-router-dom'

interface CommunityPageProps {}

const CommunityPage: FC<CommunityPageProps> = (props: CommunityPageProps) => {
  return <Outlet></Outlet>
}

export default CommunityPage
