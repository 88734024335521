import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Post, Tag } from '../../types'
import { CONSTANTS } from '../../constants'
import { sortHelper } from '../../utils/sort'
import { xor, xorBy } from 'lodash'

// Define a type for the slice state
interface CommunityState {
  posts: Post[]
  activePost?: Post
  postSortBy: string
  activeFilters: Tag[]
  activeSearch: string
}

// Define the initial state using that type
const initialState: CommunityState = {
  posts: [],
  postSortBy: CONSTANTS.SORT.COMMUNITY.DEFAULT,
  activePost: undefined,
  activeFilters: [],
  activeSearch: '',
}

export const communitySlice = createSlice({
  name: 'communitySlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPosts: (state, action: PayloadAction<Post[] | undefined>) => {
      let posts = action.payload ?? []
      posts = sortHelper(posts, state.postSortBy)
      state.posts = posts
    },
    sortPosts: (state, action: PayloadAction<string>) => {
      let posts = [...state.posts]
      state.postSortBy = action.payload

      posts = sortHelper(posts, action.payload)

      state.posts = posts
    },
    setActivePost: (state, action: PayloadAction<Post | undefined>) => {
      state.activePost = action.payload
    },
    setActiveFilters: (state, action: PayloadAction<Tag[]>) => {
      state.activeFilters = action.payload
    },
    setCommunitySearchTerm: (state, action: PayloadAction<string>) => {
      state.activeSearch = action.payload
    },
  },
})

export const {
  setPosts,
  sortPosts,
  setActivePost,
  setActiveFilters,
  setCommunitySearchTerm
} = communitySlice.actions

export default communitySlice.reducer
