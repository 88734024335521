import {
  Avatar,
  Button,
  Heading,
  Menu,
  MenuIcon,
  Pane,
  Popover,
  Position,
  Strong,
  majorScale,
} from 'evergreen-ui'
import { FC, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { useAuth } from '../../hooks/auth.hook'
import { GetGroupsForUserResponse, User } from '../../types'
import { sendFeedbackEmail } from '../../utils/send-feedback-email'
import { generateFullName, generateUsername } from '../../utils/username'
import { AppNavLinks } from '../SideNav/AppTabs'
import styles from './TopNav.module.scss'

interface TopNavProps {}

const TopNav: FC<TopNavProps> = () => {
  const { logout } = useAuth()
  const location = useLocation()
  const [fullTitle, setFullTitle] = useState('')
  const [fullName, setFullName] = useState('')
  const [authGroups, setAuthGroups] = useState<string[] | undefined>([])
  const user: User | undefined = useAppSelector((state) => state.auth.user)

  const { getGroupsForUser } = useAuth()

  // when the user logs in, check their auth groups
  useEffect(() => {
    if (getGroupsForUser) {
      getGroupsForUser()
        .then((res: GetGroupsForUserResponse[]) => {
          if (res) {
            setAuthGroups(
              res.map((group) => group.GroupName)
            )
          }
        })
    }
  }, [getGroupsForUser])

  useEffect(() => {
    if (user) {
      setFullTitle(generateUsername(user))
      setFullName(generateFullName(user))
    }
  }, [user])

  return (
    <Pane
      display="flex"
      padding={majorScale(2)}
      background="white"
      border="default"
      height={majorScale(10)}
      className={styles.TopNav}
    >
      <Pane flex={1} alignItems="center" display="flex">
        <Heading size={600}>MSKexperts.com</Heading>
      </Pane>
      <Pane display="flex" alignItems="center">
        <Popover
          position={Position.BOTTOM_RIGHT}
          content={
            <Menu>
              <Menu.Group>
               {AppNavLinks.map((link) => 
                  (link.group === undefined || authGroups?.includes(link.group)) && (link.externalUrl ?
                    <Menu.Item key={link.name} is="a" href={link.externalUrl} target="_blank" rel="noopener noreferrer">
                      {link.name}
                    </Menu.Item> :
                    <Menu.Item key={link.name} is={NavLink} to={link.route}>
                      {link.name}
                    </Menu.Item>
                  )
                )}
              </Menu.Group>
              <Menu.Divider />
              <Menu.Group>
                <Menu.Item is={NavLink} to="account">
                  My Account
                </Menu.Item>
              </Menu.Group>
              <Menu.Divider />
              <Menu.Group>
                <Menu.Item onSelect={() => sendFeedbackEmail()} intent="info">
                  Share Feedback
                </Menu.Item>
              </Menu.Group>
              <Menu.Divider />
              <Menu.Group>
                <Menu.Item onSelect={() => logout()} intent="danger">
                  Logout
                </Menu.Item>
              </Menu.Group>
            </Menu>
          }
        >
          <Button appearance="minimal" gap={majorScale(1)}>
            <Avatar name={fullName} color="blue" />
            <Strong>{fullTitle}</Strong>
            <MenuIcon color="#696f8c"></MenuIcon>
          </Button>
        </Popover>
      </Pane>
    </Pane>
  )
}

export default TopNav
