import React, { FC } from 'react';
import ReactPlayer from 'react-player';
import { Pane, majorScale, Heading, Spinner } from 'evergreen-ui';
import { CONSTANTS } from '../../constants';
import { User, Video } from '../../types';
import RatingWithSurvey from '../RatingWithSurvey/RatingWithSurvey';
import styles from './VideoPlayerContainer.module.scss';

interface VideoPlayerContainerProps {
  video: Video;
  user: User;
}

const VideoPlayerContainer: FC<VideoPlayerContainerProps> = ({ video, user }) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <Pane padding={majorScale(2)} className={styles.videoContainer}>
      <div className={styles.videoWrapper}>
        <ReactPlayer
          className={styles.VideoPlayer}
          url={video.video_url}
          light={video.thumbnail_url}
          playing={true}
          config={{
            file: {
              forceHLS: !isSafari,
              forceVideo: true,
              attributes: {
                poster: video.thumbnail_url,
              },
              hlsOptions: {
                xhrSetup: function (xhr: any, url: string) {
                  xhr.withCredentials = true; // send cookies
                },
              },
            },
          }}
          width="100%"
          height="400px"
          controls={true}
          fallback={<Spinner />}
          playsinline={true}
        />
        <img src={`${process.env.PUBLIC_URL}/tni-logo.png`} alt="Watermark" className={styles.watermark} />
      </div>
      <Pane marginTop={majorScale(3)}>
        <Heading size={400} fontWeight={700} marginBottom={majorScale(2)}>
          Description
        </Heading>
        <Pane marginBottom={majorScale(5)}>{video.long_description}</Pane>
        <Pane
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="space-between"
          marginTop={majorScale(5)}
        >
          <RatingWithSurvey
            route={CONSTANTS.ROUTES.PATIENT_VIDEOS}
            videoId={video.id}
            userId={user.id}
            ratings={video.ratings}
            upvotes={video.meta?.upvotes}
            displayText="This video provides information that will improve my practice: "
          ></RatingWithSurvey>
        </Pane>
        {/* {renderApprovalBadge()} */}
      </Pane>
    </Pane>
  );
};

export default VideoPlayerContainer;
