import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider'
import { LoginSession, User } from '../../types'

// Define a type for the slice state
interface AuthState {
  loginSession?: LoginSession
  authResult?: AuthenticationResultType
  user?: User
}

// Define the initial state using that type
const initialState: AuthState = {
  loginSession: undefined,
  authResult: undefined,
  user: undefined,
}

export const authSlice = createSlice({
  name: 'authSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAuthResult: (
      state,
      action: PayloadAction<AuthenticationResultType | undefined>,
    ) => {
      state.authResult = action.payload
    },
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload
    },
    setLoginSession: (state, action: PayloadAction<LoginSession | undefined>) => {
      state.loginSession = action.payload
    },
    clearAuthState: (state) => {
      state.user = undefined
      state.authResult = undefined
      state.loginSession = undefined
    }
  },
})

export const { setAuthResult, setUser, setLoginSession, clearAuthState } = authSlice.actions

export default authSlice.reducer
