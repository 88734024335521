import { Pane, majorScale, Card, Heading } from 'evergreen-ui'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { CONSTANTS } from '../../constants'
import { generatePageTitle } from '../../utils/generate-page-title'
import styles from './CoursesPage.module.scss'

interface CoursesPageProps {
  flex?: number
}

const CoursesPage: FC<CoursesPageProps> = (props: CoursesPageProps) => {
  const title = generatePageTitle(CONSTANTS.TITLES.COURSES)

  return (
    <Pane
      flex={props.flex ?? 1}
      className={styles.CoursesPage}
      data-testid="CoursesPage"
      padding={majorScale(4)}
      background="tint2"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Card
        border="default"
        padding={majorScale(2)}
        display="flex"
        flexDirection="column"
        background="white"
      >
        <Heading size={800} marginBottom={majorScale(1)}>
          Course
        </Heading>
      </Card>
    </Pane>
  )
}

export default CoursesPage
