import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import { initializeAxios } from './plugins/axios.config'
import { ThemeProvider } from 'evergreen-ui'

import { Provider } from 'react-redux'
import { store } from './store'
import { initializeTheme } from './plugins/theme.config'
import Router from './plugins/routes.config'
import { AuthProvider } from './hooks/auth.hook'
import { HelmetProvider } from 'react-helmet-async'

// initialize Axios
initializeAxios()

// initialize theme
const theme = initializeTheme()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <ThemeProvider value={theme}>
          <BrowserRouter>
            <AuthProvider>
              <Router />
            </AuthProvider>
          </BrowserRouter>
        </ThemeProvider>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
