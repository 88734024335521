import { Button, Heading, Pane, PlusIcon, majorScale } from 'evergreen-ui'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import PostsContainer from '../../../components/PostsContainer/PostsContainer'
import { CONSTANTS } from '../../../constants'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { useAuth } from '../../../hooks/auth.hook'
import { useGetPostsQuery } from '../../../services/posts/posts.service'
import { GetGroupsForUserResponse, Post, Tag } from '../../../types'
import { generatePageTitle } from '../../../utils/generate-page-title'
import {
  setActiveFilters,
  setCommunitySearchTerm,
  setPosts,
  sortPosts,
} from '../Community.slice'
import styles from './PostListPage.module.scss'

interface PostListPageProps {}

const PostListPage: FC<PostListPageProps> = () => {
  const title = generatePageTitle(CONSTANTS.TITLES.COMMUNITY)

  let navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [delayedSearchTerm, setDelayedSearchTerm] = useState<string>('')
  const [searchTimeout, setSearchTimeout] = useState<any>(undefined)

  const [isAuthorized, setIsAuthorized] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { getGroupsForUser } = useAuth()

  // get params from store
  const posts: Post[] = useAppSelector((state) => state.community.posts)
  const activeFilters: Tag[] = useAppSelector(
    (state) => state.community.activeFilters,
  )
  const activeSearch: string = useAppSelector(
    (state) => state.community.activeSearch,
  )
  const postsSortBy: string = useAppSelector(
    (state) => state.community.postSortBy,
  )

  // API call to get the video data
  const { data: getAllPostsData, isFetching } = useGetPostsQuery(
    { search: delayedSearchTerm, filter: activeFilters?.flatMap(t => t.name).join(',') },
    {
      refetchOnMountOrArgChange: 300,
    },
  )

  useEffect(() => {
    // clear the timeout when the component unmounts
    return () => {
      clearTimeout(searchTimeout)
    }
  }, [])

  // when the user logs in, check their auth groups
  useEffect(() => {
    setIsLoading(true)
    if (getGroupsForUser) {
      getGroupsForUser()
        .then((res: GetGroupsForUserResponse[]) => {
          if (res) {
            setIsAuthorized(
              res.some((group) => group.GroupName === CONSTANTS.GROUPS.FORUM)
            )
            setIsLoading(false)
          }
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    }
  }, [getGroupsForUser])

  // get all posts on page load
  useEffect(() => {
    dispatch(setPosts(getAllPostsData))
  }, [getAllPostsData])

  // sort the posts
  const sortPostOrder = (sortBy: string) => {
    dispatch(sortPosts(sortBy))
  }
  const setPostFilter = (filterBy: Tag[]) => {
    dispatch(setActiveFilters(filterBy))
  }
  const setActiveSearch = (searchBy: string) => {
    // clear any existing interval
    clearTimeout(searchTimeout)

    // set the search term
    dispatch(setCommunitySearchTerm(searchBy))

    // search after 500ms
    setSearchTimeout(
      setTimeout(() => {
        setDelayedSearchTerm(searchBy)
      }, 500),
    )
  }

  const loadPost = (postId: string) => {
    navigate(postId)
  }

  const createAPost = () => {
    navigate(`${CONSTANTS.ROUTES.COMMUNITY}${CONSTANTS.ROUTES.WRITE_POST}`)
  }

  return (
    <Pane
      display="flex"
      flex={4}
      className={styles.CommunityPage}
      data-testid="CommunityPage"
      padding={majorScale(4)}
      background="tint2"
      flexDirection="column"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isLoading ? (
        <Heading>Loading...</Heading>
      ) : isAuthorized ? (
        <>
          <Pane display='flex' alignItems='center' gap={majorScale(2)}>
            <Heading size={900} marginBottom={majorScale(1)} margin={0}>
              Community
            </Heading>
            <Button
              iconBefore={PlusIcon}
              fontWeight={'bold'}
              appearance="primary"
              onClick={() => createAPost()}
            >
              Create Post
            </Button>
          </Pane>
          <Pane display="flex" marginTop={majorScale(4)}>
            <PostsContainer
              posts={posts}
              activeSearch={activeSearch}
              doSort={(sortBy: string) => {
                sortPostOrder(sortBy)
              }}
              doFilter={(filterBy: Tag[]) => {
                setPostFilter(filterBy)
              }}
              doSearch={(searchTerm: string) => {
                setActiveSearch(searchTerm)
              }}
              activeFilter={activeFilters}
              activeSort={postsSortBy}
              sortOptions={CONSTANTS.SORT.COMMUNITY.OPTIONS}
              onPostItemClick={(postId) => {
                loadPost(postId)
              }}
              isLoading={isFetching}
            ></PostsContainer>
          </Pane>
        </>
      ) : (
        <Heading>You are not authorized to view this page.</Heading>
      )}
    </Pane>
  )
}
export default PostListPage
