import { SearchInput, SearchInputProps } from 'evergreen-ui'
import React, { ChangeEvent, FC, useEffect } from 'react'
import styles from './SearchBox.module.scss'

interface SearchBoxProps extends SearchInputProps {
  doSearch: (search: string) => void
}

const SearchBox: FC<SearchBoxProps> = (props: SearchBoxProps) => {
  const { doSearch, ...inputProps } = props

  return (
    <SearchInput
      {...inputProps}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        props.doSearch(e.target.value)
      }
    ></SearchInput>
  )
}

export default SearchBox
