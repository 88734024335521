import {
  Button,
  Heading,
  Pane,
  Spinner,
  Text,
  TextInputField,
  majorScale
} from 'evergreen-ui'
import { ChangeEvent, FC, FormEvent, useState } from 'react'
import { useSubscribeMutation } from '../../services/subscribers/subscribers.service'

interface SubscribePageProps { }

const SubscribePage: FC<SubscribePageProps> = () => {
  const [formValue, setFormValue] = useState({
    email: '',
    first_name: '',
    last_name: '', 
  })
  const [validationErrors, setValidationErrors] = useState({
    email: false,
  })

  const [
    callSubscribeApi,
    { isLoading: isSubscribing, isSuccess: isEditSaveSuccess },
  ] = useSubscribeMutation()

  const doSubscribe = (e?: FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault()
    }
    const isValid = validate()

    if (isValid) {
      callSubscribeApi(formValue)
    }
  }

  const validate = () => {
    const errors = {
      email: false,
    }

    if (!formValue.email) {
      errors.email = true
    }

    setValidationErrors(errors)

    return Object.values(errors).every((value) => value === false)
  }

  return (
    <Pane width="70%">
      <Heading size={900}>MSKexperts.com</Heading>
      <Heading size={800} color="#696F8C" marginBottom={majorScale(4)}>
        Subscribe
      </Heading>
      <Heading size={400} marginBottom={majorScale(4)}>
        Enter your email address to receive a notification when our site
        goes live and receive a special promotional price for your subscription!
      </Heading>
      <form onSubmit={(e) => doSubscribe(e)}>
        <TextInputField
          id="first_name"
          label="First Name"
          required
          placeholder="First Name"
          value={formValue.first_name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValue({ ...formValue, first_name: e.target.value })
          }
          name="first_name"
        ></TextInputField>
        
        <TextInputField
          id="last_name"
          label="Last Name"
          required
          placeholder="Last Name"
          value={formValue.last_name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValue({ ...formValue, last_name: e.target.value })
          }
          name="last_name"
        ></TextInputField>

        <TextInputField
          id="email"
          label="Email Address"
          required
          placeholder="Email Address"
          type={'email'}
          value={formValue.email}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValue({ ...formValue, email: e.target.value })
          }
          name="email"
          isInvalid={validationErrors.email}
        ></TextInputField>

        <Button
          marginBottom={majorScale(4)}
          fontSize="14px"
          type="submit"
          disabled={isSubscribing}
        >
          {isSubscribing ? (
            <Spinner size={majorScale(2)}></Spinner>
          ) : (
            <Text>Subscribe</Text>
          )}
        </Button>
        { isEditSaveSuccess && (
          <Heading size={800} color="green">Success!</Heading>
        ) }
      </form>
    </Pane>
  )
}

export default SubscribePage
