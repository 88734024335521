import { format } from 'date-fns'

export const convertToFriendlyDate = (date?: string, withTime?: boolean) => {
  if (!date) {
    return
  }

  const formatString = withTime ? 'dd MMM yyyy @ h:mm a' : 'dd MMM yyyy';


  return format(new Date(date), formatString)
}
